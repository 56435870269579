import React, {useState} from 'react';
import {MethodLibrary} from 'pages/LibraryPage/MethodLibrary';
import {NavTabs} from 'pages/LibraryPage/NavTabs';
import {adminLibraryTabs} from 'constants/library.constants';
import {Link} from 'react-router-dom';
import {RoutePath} from 'Router';

import {MethodStatus} from 'types';
import {Button, SearchInput} from '../../components';
import {useLocalizedNavigation} from 'hooks';

interface AdminDashboardPageProps {
  defaultTab?: string;
}

export const AdminDashboardPage: React.FC<AdminDashboardPageProps> = ({defaultTab = 'methods'}) => {
  const navigate = useLocalizedNavigation();
  const handleMethodClick = React.useCallback((id: number) => {
    navigate(`${RoutePath.adminDashboard}/methods/${id}`);
  }, []);

  const [query, setQuery] = useState<string>('');
  const [activeTab, setActiveTab] = useState<string>(defaultTab);

  return (
    <div>
      <div className="flex flex-col px-4 sm:px-6 lg:px-24 py-12 top-16 gap-4 max-w-[1600px] m-auto">
        <p className="text-2xl font-extrabold text-gray-900">Library</p>
        <div className="flex justify-between items-start w-full border-black/[0.6] border-b mb-8">
          <div className="flex flex-col-reverse sm:flex-row justify-between w-full flex-wrap gap-6">
            <NavTabs tabs={adminLibraryTabs} activeTab={activeTab} setActiveTab={setActiveTab} />
            <SearchInput query={query} setQuery={setQuery} className="mb-4" />
          </div>
        </div>
        <div className="flex">
          <Link to={`${RoutePath.adminDashboard}/methods/new`}>
            <Button>+ New Method</Button>
          </Link>
        </div>
        {activeTab === 'methods' && (
          <MethodLibrary handleMethodClick={handleMethodClick} query={query} statuses={[MethodStatus.DRAFT, MethodStatus.PUBLISHED]} showOthers />
        )}
        {activeTab === 'draft methods' && <MethodLibrary handleMethodClick={handleMethodClick} query={query} statuses={[MethodStatus.DRAFT]} />}
      </div>
    </div>
  );
};
