import React from 'react';
import {Card, LocalizedText, RichTypography} from 'components';
import {Flag, Puzzle, Fire} from 'assets';

import styles from './DescriptionCards.module.scss';
import {upperFirst} from '../../../../services';

interface DescriptionCardsProps {
  method: ToolboxObject.MethodDetails;
}

export const DescriptionCards: React.FC<DescriptionCardsProps> = ({method}) => {
  const color = method.subcategory?.category.color;
  return (
    <div className={styles.descriptionCards}>
      <div className={styles.wrapper}>
        <Card headerIcon={<Puzzle />} headerTitleKey="pages.library.method.requirements" color={color}>
          <ul>
            {method?.infos?.requirement?.length ? (
              method.infos.requirement.map((info) => {
                return (
                  <li key={info.id}>
                    <RichTypography text={upperFirst(info.description)} />
                  </li>
                );
              })
            ) : (
              <li>
                <LocalizedText id="pages.library.method.comingSoon" />
              </li>
            )}
          </ul>
        </Card>

        <Card headerIcon={<Flag />} headerTitleKey="pages.library.method.results" color={color}>
          <ul>
            {method?.infos?.result?.length ? (
              method.infos.result.map((info) => {
                return (
                  <li key={info.id}>
                    <RichTypography text={upperFirst(info.description)} />
                  </li>
                );
              })
            ) : (
              <li>
                <LocalizedText id="pages.library.method.comingSoon" />
              </li>
            )}
          </ul>
        </Card>
      </div>
      <Card headerIcon={<Fire />} headerTitleKey="pages.library.method.benefits" color={color}>
        <ul>
          {method?.infos?.benefit?.length ? (
            method.infos.benefit.map((info) => {
              return (
                <li key={info.id}>
                  <RichTypography text={upperFirst(info.description)} />
                </li>
              );
            })
          ) : (
            <li>
              <LocalizedText id="pages.library.method.comingSoon" />
            </li>
          )}
        </ul>
      </Card>
    </div>
  );
};
