import {NavigateOptions, To, useNavigate} from 'react-router-dom';
import {useLocale} from './useLocale';

export const useLocalizedNavigation = () => {
  const locale = useLocale();
  const navigate = useNavigate();

  const localizedNavigate = (to: To, options?: NavigateOptions) => {
    navigate(`/${locale}${to}`, options);
  };
  return localizedNavigate;
};
