import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {Carousel} from 'react-responsive-carousel';

interface MethodImageCarouselProps {
  files?: ToolboxObject.MethodFile[];
  width?: number;
}

export const MethodImageCarousel = ({files = [], width = 500}: MethodImageCarouselProps) => {
  if (!files.length) {
    return null;
  }
  return (
    <Carousel showThumbs={false} showStatus={false} showIndicators={files.length > 1}>
      {files.map(({id, link, display_name}) => {
        return <img width={width} src={link} alt={display_name} key={id} />;
      })}
    </Carousel>
  );
};
