import React, {PropsWithChildren} from 'react';
import {createBrowserRouter, createRoutesFromElements, Navigate, Outlet, Route, RouterProvider, useRouteError} from 'react-router-dom';

import {
  AdminDashboardPage,
  AdminMethodPage,
  ErrorPage,
  ForgotPasswordPage,
  LibraryPage,
  LoginPage,
  ResetPasswordPage,
  RootPage,
  WorkshopLibraryPage,
  RegisterPage,
  AdminTranslationPage,
} from 'pages';
import {errorHandler} from 'services';
import {useStateLocation, useLocale} from 'hooks';
import {adminLibraryTabs, libraryTabs} from 'constants/library.constants';
import {NewWorkshopPage, WorkshopPage} from 'pages/WorkshopsPage';
import {PageLayout} from 'layout';
import {UserRole} from 'types';
import {useMethods, useRecipes, useSelf, useTranlsations, useWorkshops} from './services/query';
import {RelativeRoutePath, SUPPORTED_LANGUAGES} from 'constants/app.constants';
import {IntlProvider} from 'react-intl';
import {Loader, LocalizedNavigate} from 'components';

export enum RoutePath {
  root = '/',
  error = '/error',
  list = '/list',
  library = '/library',
  workshopLibrary = '/workshops',
  workshopEditor = '/editor',
  login = '/login',
  register = '/register',
  resetPassword = '/reset/password',
  forgotPassword = '/forgot/password',
  adminDashboard = '/admin-dashboard',
  adminTranslation = '/admin-translations',
}

const ErrorBoundary = () => {
  const location = useStateLocation();

  const error = useRouteError();
  const extendedError = errorHandler.handle(error);

  return location.pathname === RoutePath.root ? <div>{extendedError.message}</div> : <Navigate to="/" replace />;
};

const ProtectedRoute: React.FC<PropsWithChildren> = () => {
  // check data is being fetched
  useMethods();
  useRecipes();
  useWorkshops();
  useSelf();
  if (!localStorage.getItem('userSession')) {
    return <LocalizedNavigate to={RoutePath.login} replace />;
  }
  return <Outlet />;
};

const AdminRoute: React.FC = () => {
  const {data: user, isLoading} = useSelf();
  if (!isLoading && user?.role !== UserRole.ADMIN) {
    return <LocalizedNavigate to={RoutePath.root} replace />;
  }
  return (
    <ProtectedRoute>
      <Outlet />
    </ProtectedRoute>
  );
};

const LanguageRoute: React.FC = () => {
  const {data, isLoading} = useTranlsations();
  const locale = useLocale();
  const messages = React.useMemo(() => {
    const translations: Record<string, string> = {};
    if (data) {
      data.forEach((t) => {
        const {key, id, ...rest} = t;
        translations[key] = rest[locale];
      });
    }
    return translations;
  }, [data, locale]);
  return (
    <IntlProvider messages={messages} locale={locale} defaultLocale="en">
      {isLoading ? <Loader /> : <Outlet />}
    </IntlProvider>
  );
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      {SUPPORTED_LANGUAGES.map((lang) => (
        <Route path={lang} key={lang} element={<LanguageRoute />}>
          <Route ErrorBoundary={ErrorBoundary} element={<PageLayout />}>
            {/* Protected routes */}
            <Route element={<ProtectedRoute />}>
              <Route path={RelativeRoutePath.root} element={<RootPage />} />
              <Route path={RelativeRoutePath.library}>
                <Route index element={<Navigate to="recipes" />} />
                {libraryTabs.map((tab) => (
                  <Route key={`${RoutePath.library}/${tab.id}`} path={tab.id} element={<LibraryPage defaultTab={tab.id} />} />
                ))}
              </Route>
              <Route path={RelativeRoutePath.workshopEditor}>
                <Route index element={<Navigate to="new" />} />
                <Route path=":workshopId" element={<WorkshopPage />} />
                <Route path="new" element={<NewWorkshopPage />} />
              </Route>
              <Route path={RelativeRoutePath.workshopEditor} element={<NewWorkshopPage />} />
              <Route path={RelativeRoutePath.workshopLibrary} element={<WorkshopLibraryPage />} />
            </Route>

            {/* Admin routes */}
            <Route element={<AdminRoute />}>
              <Route path={RelativeRoutePath.workshopEditor}>
                <Route path="recipe/:workshopId" element={<WorkshopPage recipe />} />
              </Route>
              <Route path={RelativeRoutePath.adminDashboard}>
                <Route index element={<Navigate to="methods" />} />
                {adminLibraryTabs.map((tab) => (
                  <Route key={`${RoutePath.adminDashboard}/${tab.id}`} path={tab.id} element={<AdminDashboardPage defaultTab={tab.id} />} />
                ))}
                <Route path="methods/:id" element={<AdminMethodPage />} />
              </Route>
              <Route path={RelativeRoutePath.adminTranslation} element={<AdminTranslationPage />} />
            </Route>
            <Route path={RelativeRoutePath.error} element={<ErrorPage />} />
            <Route path="*" element={<LocalizedNavigate to={RoutePath.root} />} />
          </Route>

          {/* Unprotected routes */}
          <Route path={RelativeRoutePath.register} element={<RegisterPage />} />
          <Route path={RelativeRoutePath.resetPassword} element={<ResetPasswordPage />} />
          <Route path={RelativeRoutePath.login} element={<LoginPage />} />
          <Route path={RelativeRoutePath.forgotPassword} element={<ForgotPasswordPage />} />
        </Route>
      ))}
      <Route path="*" element={<Navigate to="en" />} />
    </>
  )
);

export const Router: React.FC = () => {
  return <RouterProvider router={router} />;
};
