import React from 'react';

export const Fire = ({...props}) => {
  return (
    <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75676 0.932431C9.03398 1.00605 9.26624 1.19517 9.39452 1.45172C9.87808 2.41884 10.239 3.00581 10.6021 3.48173C10.9696 3.96345 11.3584 4.35782 11.9497 4.9492C13.3164 6.31585 14 8.10905 14 9.89896C14 11.6889 13.3164 13.4821 11.9497 14.8487C9.21608 17.5824 4.78392 17.5824 2.05025 14.8487C0.683615 13.4821 2.35307e-06 11.6889 0 9.89896C-2.95743e-06 8.10905 0.683611 6.31585 2.05025 4.9492C2.33625 4.66321 2.76637 4.57765 3.14004 4.73243C3.51372 4.88721 3.75736 5.25185 3.75736 5.65631C3.75736 6.77613 3.82729 7.62938 4.15481 8.30996C4.33491 8.6842 4.61585 9.05008 5.10004 9.37657C5.21568 8.3158 5.42752 7.02341 5.71375 5.7967C5.93911 4.83088 6.21939 3.86666 6.553 3.06259C6.71986 2.66041 6.90855 2.27747 7.12285 1.94683C7.33145 1.625 7.59945 1.29751 7.94539 1.06688C8.18405 0.907775 8.47954 0.858811 8.75676 0.932431ZM9.12132 14.0202C7.94975 15.1918 6.05025 15.1918 4.87868 14.0202C4.29289 13.4345 4 12.6667 4 11.8989C4 11.8989 4.87868 12.3989 6.50005 12.3989C6.50005 11.3989 7.00005 8.39892 7.75005 7.89893C8.25005 8.89892 8.53553 9.19183 9.12132 9.77761C9.70711 10.3634 10 11.1312 10 11.8989C10 12.6667 9.70711 13.4345 9.12132 14.0202Z"
        fill="black"
      />
    </svg>
  );
};
