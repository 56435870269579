import React from 'react';
import {Button, Form} from 'antd';
import {PlusOutlined, MinusCircleOutlined} from '@ant-design/icons';
import {EditorFormItem, TextEditor} from 'components';
import styles from '../../AdminPage.module.scss';

interface DynamicListProps {
  name: string;
  title: string;
}

export const DynamicList: React.FC<DynamicListProps> = ({name, title}) => {
  return (
    <Form.List name={name}>
      {(fields, {add, remove}, {errors}) => {
        return (
          <>
            {fields.map((field, index) => (
              <Form.Item label={index === 0 ? title : ''} rules={[{required: true}]} key={field.key} required>
                <EditorFormItem
                  {...field}
                  validateTrigger={['onChange', 'onBlur']}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: 'Field is required',
                    },
                  ]}
                  noStyle
                  initialValue=""
                >
                  <TextEditor />
                </EditorFormItem>
                {fields.length > 1 ? <MinusCircleOutlined className={styles.dynamicDeleteButton} onClick={() => remove(field.name)} /> : null}
              </Form.Item>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} style={{width: '80%'}} icon={<PlusOutlined />}>
                Add new
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        );
      }}
    </Form.List>
  );
};
