import {Button, Form, Input, Select} from 'antd';
import React, {useState} from 'react';
import {toast} from 'react-toastify';
import {Modal} from '../Modal';
import {useInvite} from '../../services/query';
import {useIntl} from 'react-intl';
import {LocalizedText} from 'components/LocalizedText';

interface InviteModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const InviteModal: React.FC<InviteModalProps> = ({isOpen, onClose}) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const {mutateAsync: invite, isLoading} = useInvite();
  const onFinish = async (values: {email: string; firstName: string; lastName: string; role: string}) => {
    try {
      await invite(values);
      form.resetFields();
      toast.success(intl.formatMessage({id: 'toast.invite.success'}), {
        position: 'top-center',
        autoClose: 1000,
        theme: 'dark',
      });
      onClose();
    } catch (e) {
      toast.error(intl.formatMessage({id: 'toast.invite.fail'}), {
        position: 'top-center',
        autoClose: 1000,
        theme: 'dark',
      });
    }
  };
  const [disabledInvite, setDisabledInvite] = useState(true);

  const handleFormChange = () => {
    setDisabledInvite(
      !form.isFieldsTouched(true) ||
        form
          .getFieldsError()
          .slice(0, 3)
          .filter(({errors}) => errors.length).length > 0
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="h-fit max-w-[500px] mx-auto">
      <p className="font-bold text-xl mb-8">
        <LocalizedText id="modals.inviteUser.title" />
      </p>
      <Form form={form} name="Invite new User" onFinish={onFinish} layout="vertical">
        <Form.Item
          name="email"
          label={intl.formatMessage({id: 'common.email'})}
          rules={[
            {
              type: 'email',
              message: intl.formatMessage({id: 'modals.inviteUser.emailInvalid'}),
            },
            {
              required: true,
              message: intl.formatMessage({id: 'modals.inviteUser.emailRequired'}),
            },
          ]}
        >
          <Input onChange={handleFormChange} />
        </Form.Item>
        <Form.Item
          name="firstName"
          label={intl.formatMessage({id: 'modals.inviteUser.firstName'})}
          tooltip={intl.formatMessage({id: 'modals.inviteUser.firstNameTooltip'})}
          rules={[{required: true, message: intl.formatMessage({id: 'modals.inviteUser.firstNameRequired'}), whitespace: true}]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="lastName"
          label={intl.formatMessage({id: 'modals.inviteUser.lastName'})}
          tooltip={intl.formatMessage({id: 'modals.inviteUser.lastNameTooltip'})}
          rules={[{required: true, message: intl.formatMessage({id: 'modals.inviteUser.lastNameRequired'}), whitespace: true}]}
        >
          <Input onChange={handleFormChange} />
        </Form.Item>
        <Form.Item name="role" label={intl.formatMessage({id: 'modals.inviteUser.role'})}>
          <Select
            defaultValue={{value: '', label: intl.formatMessage({id: 'modals.inviteUser.roleNone'})}}
            options={[
              {value: '', label: intl.formatMessage({id: 'modals.inviteUser.roleNone'})},
              {value: 'miro', label: intl.formatMessage({id: 'modals.inviteUser.roleMiro'})},
              {value: 'admin', label: intl.formatMessage({id: 'modals.inviteUser.roleAdmin'})},
            ]}
            onChange={handleFormChange}
          />
        </Form.Item>
        <Form.Item className="my-4">
          <Button type="primary" htmlType="submit" disabled={disabledInvite} loading={isLoading}>
            <LocalizedText id="modals.inviteUser.sendInvitation" />
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
