import React from 'react';

export const Puzzle: React.FC = ({...props}) => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.03711 2.03369C8.03711 1.20526 8.70868 0.533691 9.53711 0.533691C10.3655 0.533691 11.0371 1.20526 11.0371 2.03369V2.53369C11.0371 3.08598 11.4848 3.53369 12.0371 3.53369H15.0371C15.5894 3.53369 16.0371 3.98141 16.0371 4.53369V7.53369C16.0371 8.08598 15.5894 8.53369 15.0371 8.53369H14.5371C13.7087 8.53369 13.0371 9.20526 13.0371 10.0337C13.0371 10.8621 13.7087 11.5337 14.5371 11.5337H15.0371C15.5894 11.5337 16.0371 11.9814 16.0371 12.5337V15.5337C16.0371 16.086 15.5894 16.5337 15.0371 16.5337H12.0371C11.4848 16.5337 11.0371 16.086 11.0371 15.5337V15.0337C11.0371 14.2053 10.3655 13.5337 9.53711 13.5337C8.70868 13.5337 8.03711 14.2053 8.03711 15.0337V15.5337C8.03711 16.086 7.58939 16.5337 7.03711 16.5337H4.03711C3.48482 16.5337 3.03711 16.086 3.03711 15.5337V12.5337C3.03711 11.9814 2.58939 11.5337 2.03711 11.5337H1.53711C0.708682 11.5337 0.0371094 10.8621 0.0371094 10.0337C0.0371094 9.20526 0.708682 8.53369 1.53711 8.53369H2.03711C2.58939 8.53369 3.03711 8.08598 3.03711 7.53369V4.53369C3.03711 3.98141 3.48482 3.53369 4.03711 3.53369H7.03711C7.58939 3.53369 8.03711 3.08598 8.03711 2.53369V2.03369Z"
        fill="black"
      />
    </svg>
  );
};
