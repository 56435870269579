import React from 'react';
import {Disclosure, Transition} from '@headlessui/react';

interface CollapsibleProps {
  title: string;
  children: React.ReactNode;
  defaultOpen?: boolean;
}
export const Collapsible: React.FC<CollapsibleProps> = ({title, defaultOpen = true, children}) => {
  return (
    <Disclosure as="div" defaultOpen={defaultOpen}>
      {({open}) => {
        return (
          <>
            <Disclosure.Button className="text-start text-base font-bold capitalize px-2 py-1 w-full bg-gray-100 rounded-lg">{`${
              open ? '-' : '+'
            } ${title}`}</Disclosure.Button>
            <Transition
              enter="transition ease duration-200 transform"
              enterFrom="opacity-0 "
              enterTo="opacity-100"
              leave="transition ease duration-100 transform"
              leaveFrom="opacity-100 "
              leaveTo="opacity-0 "
            >
              <Disclosure.Panel className="text-gray-500">{children}</Disclosure.Panel>
            </Transition>
          </>
        );
      }}
    </Disclosure>
  );
};
