import {LocalizedText} from 'components/LocalizedText';
import {useOutsideClick} from 'hooks';
import React from 'react';

export interface IOption {
  label: string;
  value: string;
}

type MultipleSelectProps = {
  titleKey: string;
  options: IOption[];
  selectedValues: string[];
  onChange(values: string[]): void;
};

const MultipleSelectItem: React.FC<{selected?: boolean; onClick: () => void; label: string}> = ({selected, onClick, label}) => {
  return (
    <li>
      <div
        className={`flex py-2 px-4 justify-between items-center cursor-pointer hover:bg-gray-100 ${selected ? 'bg-gray-50' : ''}`}
        onClick={onClick}
        role="button"
        tabIndex={0}
      >
        <span className="text-gray-700 font-medium leading-6 text-sm">{label}</span>
        <div className="w-5 min-w-[20px] h-5">
          {selected ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <rect x="0.5" y="0.5" width="19" height="19" rx="3.5" fill="#1F2933" />
              <rect x="0.5" y="0.5" width="19" height="19" rx="3.5" stroke="#1F2933" />
              <path
                d="M15.259 5.99123C15.4934 6.22564 15.625 6.54352 15.625 6.87498C15.625 7.20643 15.4934 7.52432 15.259 7.75873L9.00901 14.0087C8.7746 14.2431 8.45672 14.3747 8.12526 14.3747C7.79381 14.3747 7.47592 14.2431 7.24152 14.0087L4.74151 11.5087C4.51382 11.273 4.38782 10.9572 4.39067 10.6295C4.39352 10.3017 4.52498 9.98821 4.75674 9.75645C4.9885 9.52469 5.30202 9.39323 5.62976 9.39038C5.95751 9.38754 6.27326 9.51353 6.50901 9.74123L8.12526 11.3575L13.4915 5.99123C13.7259 5.75689 14.0438 5.62524 14.3753 5.62524C14.7067 5.62524 15.0246 5.75689 15.259 5.99123Z"
                fill="white"
              />
            </svg>
          ) : (
            <div className="w-full h-full border rounded border-gray-300 bg-white" />
          )}
        </div>
      </div>
    </li>
  );
};

export const MultipleSelect: React.FC<MultipleSelectProps> = ({options, selectedValues, titleKey, onChange}) => {
  const [isActive, setIsActive] = React.useState(false);

  const handleClearSelection: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    onChange([]);
  };

  const ref = useOutsideClick<HTMLDivElement>(() => setIsActive(false));

  const hasSelectedValues = !!selectedValues.length;
  return (
    <div ref={ref} className="flex flex-col gap-y-1 w-full relative select-none flex-1">
      <div
        className={`rounded-md h-10 shadow-sm bg-white cursor-pointer overflow-hidden w-full ${hasSelectedValues ? '' : 'hover:bg-gray-100'}`}
        onClick={() => setIsActive(!isActive)}
        role="button"
        tabIndex={0}
      >
        <div className="flex justify-between items-center h-full">
          <div
            className={`flex gap-x-1 w-full h-full items-center px-3 border-y border-l rounded-s-md border-gray-350 border-r ${
              hasSelectedValues ? 'hover:bg-gray-100' : 'border-r-transparent'
            }`}
          >
            <span className="text-gray-950 font-medium leading-6 text-sm whitespace-nowrap">
              <LocalizedText id={titleKey} />
            </span>
            <div className={`flex items-center justify-center bg-gray-800 rounded-full w-6 h-6 ${hasSelectedValues ? 'opacity-100' : 'opacity-0'}`}>
              <span className="text-white text-xs">{selectedValues.length}</span>
            </div>
          </div>
          <div
            className="flex items-center hover:bg-gray-100 px-3 h-full border-e border-t border-b rounded-e-md border-gray-350"
            onClick={hasSelectedValues ? handleClearSelection : undefined}
            role="button"
            tabIndex={0}
          >
            {hasSelectedValues ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289L10 8.58579L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L11.4142 10L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L10 11.4142L5.70711 15.7071C5.31658 16.0976 4.68342 16.0976 4.29289 15.7071C3.90237 15.3166 3.90237 14.6834 4.29289 14.2929L8.58579 10L4.29289 5.70711C3.90237 5.31658 3.90237 4.68342 4.29289 4.29289Z"
                  fill="#6B7280"
                />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  d="M6.66699 7.50001L10.0003 4.16667L13.3336 7.50001M13.3336 12.5L10.0003 15.8333L6.66699 12.5"
                  stroke="#7B8794"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </div>
        </div>
      </div>
      <ul
        className={`
          z-10 absolute top-full mt-2 w-full bg-white rounded-md
          ring-1 ring-black ring-opacity-5 shadow-lg transition-all duration-300 ease-in-out
          ${isActive ? 'max-h-40 overflow-auto py-1' : 'max-h-0 invisible overflow-hidden'}
        `}
      >
        {options.map((option) => (
          <MultipleSelectItem
            key={option.value}
            selected={selectedValues.includes(option.value)}
            onClick={() => {
              const nextValues = selectedValues.filter((val) => option.value !== val);
              if (nextValues.length === selectedValues.length) {
                nextValues.push(option.value);
              }
              onChange(nextValues);
            }}
            label={option.label}
          />
        ))}
      </ul>
    </div>
  );
};
