import React from 'react';

import {Card, RichTypography} from 'components';
import {Puzzle, Fire} from 'assets';

import styles from './Description.module.scss';
import {upperFirst} from '../../../../services';

interface DescriptionProps {
  recipe: ToolboxObject.Workshop;
}

export const Description: React.FC<DescriptionProps> = ({recipe}) => {
  return (
    <div className={styles.description}>
      {recipe.description && <RichTypography className={styles.text} text={upperFirst(recipe.description ?? 'coming soon')} />}
      <div className={styles.wrapper}>
        {!!recipe.requirements?.length && (
          <Card headerIcon={<Puzzle />} headerTitleKey="pages.library.method.requirements" color="#E3E7EB">
            <ul>
              {recipe.requirements.map((requirement) => {
                return (
                  <li key={requirement + requirement}>
                    <RichTypography text={upperFirst(requirement)} />
                  </li>
                );
              })}
            </ul>
          </Card>
        )}
        {!!recipe.benefits?.length && (
          <Card headerIcon={<Fire />} headerTitleKey="pages.library.method.benefits" color="#E3E7EB">
            <ul>
              {recipe.benefits.map((benefit, index) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={benefit + index}>
                    <RichTypography text={upperFirst(benefit)} />
                  </li>
                );
              })}
            </ul>
          </Card>
        )}
      </div>
    </div>
  );
};
