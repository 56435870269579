import {Button, LocalizedText, MultipleSelect} from 'components';
import {useMethodFilters} from 'hooks';
import React from 'react';

export const MethodFilters: React.FC<{applyFilters: (filter: ToolboxObject.MethodFilterData) => void}> = ({applyFilters}) => {
  const [categories, setCategories] = React.useState<string[]>([]);
  const [subcategories, setSubcategories] = React.useState<string[]>([]);
  const [socialForms, setSocialForms] = React.useState<string[]>([]);
  const [formats, setFormats] = React.useState<string[]>([]);

  const handleClearFilters = React.useCallback(() => {
    setCategories([]);
    setSubcategories([]);
    setSocialForms([]);
    setFormats([]);
  }, []);

  React.useEffect(() => {
    applyFilters({
      categories,
      subcategories,
      socialForms,
      formats,
    });
  }, [categories, subcategories, socialForms, formats]);

  const options = useMethodFilters();

  React.useEffect(() => {
    if (!subcategories.length) {
      return;
    }
    const categoriesToSelect = options.subcategory.reduce(
      (acc: string[], sub) => (subcategories.includes(sub.value) ? [...new Set([...acc, sub.category])] : acc),
      []
    );

    setCategories(categoriesToSelect);
  }, [subcategories, options]);

  const hasActiveFilter = Boolean(categories.length || subcategories.length || socialForms.length || formats.length);
  return (
    <div className="flex w-full gap-2 flex-wrap items-start md:noflex-wrap">
      <div className="flex gap-2 flex-1 flex-wrap">
        <MultipleSelect titleKey="pages.library.category" options={options.category} selectedValues={categories} onChange={setCategories} />
        <MultipleSelect titleKey="pages.library.subcategory" options={options.subcategory} selectedValues={subcategories} onChange={setSubcategories} />
      </div>
      <div className="flex gap-2 flex-1 flex-wrap">
        <MultipleSelect titleKey="pages.library.socialForm" options={options.socialForm} selectedValues={socialForms} onChange={setSocialForms} />
        <MultipleSelect titleKey="pages.library.format" options={options.format} selectedValues={formats} onChange={setFormats} />
      </div>
      <div className="w-full md:w-auto">
        <Button disabled={!hasActiveFilter} onClick={handleClearFilters}>
          <div className="flex gap-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 2C8.62123 2 8.27497 2.214 8.10557 2.55279L7.38197 4H4C3.44772 4 3 4.44772 3 5C3 5.55228 3.44772 6 4 6L4 16C4 17.1046 4.89543 18 6 18H14C15.1046 18 16 17.1046 16 16V6C16.5523 6 17 5.55228 17 5C17 4.44772 16.5523 4 16 4H12.618L11.8944 2.55279C11.725 2.214 11.3788 2 11 2H9ZM7 8C7 7.44772 7.44772 7 8 7C8.55228 7 9 7.44772 9 8V14C9 14.5523 8.55228 15 8 15C7.44772 15 7 14.5523 7 14V8ZM12 7C11.4477 7 11 7.44772 11 8V14C11 14.5523 11.4477 15 12 15C12.5523 15 13 14.5523 13 14V8C13 7.44772 12.5523 7 12 7Z"
                fill="white"
              />
            </svg>
            <span className="font-semibold leading-5 text-sm text-white whitespace-nowrap">
              <LocalizedText id="pages.library.clearFilters" />
            </span>
          </div>
        </Button>
      </div>
    </div>
  );
};
