import React from 'react';
import {useLocale} from 'hooks';
import {Navigate, NavigateProps} from 'react-router-dom';

export const LocalizedNavigate = (props: NavigateProps) => {
  const locale = useLocale();
  const to = props.to.toString()[0] === '/' ? props.to.toString().slice(1) : props.to;
  const link = `/${locale}/${to}`;
  return <Navigate {...props} to={link} />;
};
