import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

import {config} from 'services';

export const languages = ['en'];

export const initI18n = async () => {
  const translations = await Promise.all(
    languages.map((language) => {
      return import(`./resources/${language}.json`);
    })
  );

  const resources = languages.reduce((acc, language, index) => {
    return {
      ...acc,
      [language]: {
        translation: translations[index],
      },
    };
  }, {});

  const cacheKey = `${config.env.appKey}_i18next`;

  i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      resources,
      supportedLngs: languages,
      interpolation: {
        escapeValue: false,
      },
      keySeparator: false,
      detection: {
        lookupQuerystring: 'language',
        lookupCookie: cacheKey,
        lookupLocalStorage: cacheKey,
        lookupSessionStorage: cacheKey,
        // uncomment the next line to stop using the cached language
        // caches: [],
      },
    });

  if (config.url.showTranlationsKeys) {
    i18n.t = (...args) => {
      return args[0] as string;
    };
  }
};

export {i18n};
