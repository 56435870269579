import React, {ChangeEventHandler, useState} from 'react';
import produce from 'immer';
import classNames from 'classnames';
import {useOutsideClick} from 'hooks';

interface EditableMethodFacilitatorProps {
  method: ToolboxObject.EditableMethod;
  facilitator?: string | null;
  update: any;
}

export const EditableMethodFacilitator: React.FC<EditableMethodFacilitatorProps> = ({method, facilitator, update}) => {
  const [editingValue, setEditingValue] = useState(facilitator || '');
  const [isEditing, setIsEditing] = useState(false);

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    return setEditingValue(event.target.value);
  };

  const onBlur = () => {
    update(
      produce(method, (draft) => {
        draft.facilitator = editingValue;
      })
    );
    setIsEditing(false);
  };
  const showFacilitator = editingValue && editingValue.trim() !== '';
  const divRef = useOutsideClick<HTMLDivElement>(onBlur);
  return (
    <div className="px-3 h-full min-h-[32px] flex items-center" ref={divRef}>
      <p className="text-sm font-bold leading-6">
        Facilitator:{' '}
        {isEditing ? (
          <input
            /* eslint-disable-next-line jsx-a11y/no-autofocus */
            autoFocus
            onChange={onChange}
            onBlur={onBlur}
            onClick={(e) => {
              return e.stopPropagation();
            }}
            className="text-xs sm:text-sm leading-5 font-medium text-black rounded-md p-1"
            value={editingValue ?? ''}
          />
        ) : (
          <span
            className={classNames(
              'font-medium text-gray-500 hover:text-black p-1 cursor-pointer rounded-md border hover:border-gray-100 border-white',
              showFacilitator ? '' : 'italic'
            )}
            onClick={(event) => {
              event.stopPropagation();
              setIsEditing(true);
            }}
            role="button"
            tabIndex={0}
          >
            {showFacilitator ? editingValue : '+ Add Facilitator'}
          </span>
        )}
      </p>
    </div>
  );
};
