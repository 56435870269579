import {RichTypography} from 'components/RichTypography';
import React from 'react';

interface DescriptionProps {
  text: string;
}

export const Description: React.FC<DescriptionProps> = ({text}) => {
  return <RichTypography className="line-clamp-3 text-xs sm:text-sm leading-5 font-medium text-gray-500" text={text} />;
};
