import React, {useMemo} from 'react';
import Fuse from 'fuse.js';
import {Collapsible, MethodLibraryTile, RecipeLibraryTile} from '../../components';
import {LoadingComponent, useMethods, useRecipes} from '../../services/query';

interface MethodLibraryProps {
  query?: string | undefined;
}

export const Favorites: React.FC<MethodLibraryProps> = ({query = ''}) => {
  const methods = useMethods();
  const recipes = useRecipes();

  const favoriteMethods = useMemo(() => {
    if (!methods.data) {
      return [];
    }
    const favorites = methods.data.filter((method: ToolboxObject.Method) => {
      return method.user_data?.is_favorite;
    });
    return query
      ? new Fuse(favorites, {keys: ['title', 'teaser'], includeScore: true, threshold: 0.5}).search(query).map((result) => {
          return result.item;
        })
      : favorites;
  }, [methods.data, query]);

  const favoriteRecipes = useMemo(() => {
    if (!recipes.data) {
      return [];
    }
    const favorites = recipes.data.filter((reicpe: ToolboxObject.Workshop) => {
      return reicpe.user_data?.is_favorite;
    });
    return query
      ? new Fuse(favorites, {keys: ['title', 'teaser'], includeScore: true, threshold: 0.5}).search(query).map((result) => {
          return result.item;
        })
      : favorites;
  }, [recipes.data, query]);

  return (
    <LoadingComponent query={methods}>
      {() => {
        return (
          <div className="flex flex-col gap-4">
            {!!favoriteRecipes.length && (
              <Collapsible title="Recipes" defaultOpen key={query ? crypto.randomUUID() : 'library-favourite-recipes'}>
                <div className="grid sm:auto-rows-[minmax(0,141px)] md:auto-rows-[minmax(0,226px)] lg:auto-rows-[minmax(0,196px)] xl:auto-rows-[minmax(0,226px)] 2xl:auto-rows-[minmax(0,248px)] gap-6 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 mb-12 mt-6">
                  {favoriteRecipes.map((recipe: ToolboxObject.Workshop) => {
                    return <RecipeLibraryTile recipe={recipe} key={`favorite${recipe.id}`} />;
                  })}
                </div>
              </Collapsible>
            )}
            {!!favoriteMethods.length && (
              <Collapsible title="Methods" defaultOpen key={query ? crypto.randomUUID() : 'library-favourite-methods'}>
                <div className="grid gap-6 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 mb-12 mt-6">
                  {favoriteMethods.map((method: ToolboxObject.Method) => {
                    return <MethodLibraryTile method={method} key={method.id} />;
                  })}
                </div>
              </Collapsible>
            )}
          </div>
        );
      }}
    </LoadingComponent>
  );
};
