import React, {Fragment} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import classNames from 'classnames';

import {ArrowLeft, Cross} from 'assets';

import styles from './Modal.module.scss';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  nested?: boolean;
  className?: string;
}

export const Modal: React.FC<ModalProps> = ({isOpen, onClose, children, nested = false, className = ''}) => {
  return isOpen ? (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className={styles.modal} style={{zIndex: nested ? 11 : 10}} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {nested ? <div className="fixed inset-0 bg-transparent" /> : <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />}
        </Transition.Child>

        <div className={classNames('fixed inset-0 z-10 overflow-y-auto', className)}>
          <div className="flex h-full min-h-full items-start justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden sm:rounded-xl  bg-white text-left shadow-xl transition-all w-full h-full sm:max-w-[84%] lg:max-w-[86%] sm:h-5/6 sm:mt-[87px]">
                <div className={classNames(styles.wrapper, 'bg-white px-4 py-6 lg:px-8 h-full')}>
                  <button
                    type="button"
                    className="absolute top-0 right-0 px-4 py-6 lg:px-8 text-base font-medium text-gray-700 focus:outline-none z-20"
                    onClick={onClose}
                  >
                    {nested ? <ArrowLeft /> : <Cross />}
                  </button>
                  {children}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  ) : null;
};
