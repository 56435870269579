import React, {useMemo} from 'react';
import Fuse from 'fuse.js';
import {Collapsible, RecipeLibraryTile} from '../../components';
import {LoadingComponent, useRecipes, useSelf} from '../../services/query';
import {groupRecipesByCategory, sortRecipeCategories} from '../../services';

interface RecipeLibraryProps {
  query?: string | undefined;
}

export const RecipeLibrary: React.FC<RecipeLibraryProps> = ({query = ''}) => {
  const self = useSelf();
  const recipes = useRecipes({sort: self.data?.role === 'admin' ? 'alphabetical' : 'lastCreated'});
  const {data} = recipes;
  const recipesByCategory = useMemo(() => {
    // @ts-ignore
    return data
      ? groupRecipesByCategory(
          query
            ? new Fuse(data, {keys: ['title', 'teaser'], includeScore: true, threshold: 0.5}).search(query).map((result) => {
                return result.item;
              })
            : data
        )
      : {};
  }, [data, query]);
  return (
    <div className="flex flex-col gap-4">
      <LoadingComponent query={recipes}>
        {() => {
          return (
            <div className="flex flex-col gap-4">
              {Object.entries(recipesByCategory)
                .sort(sortRecipeCategories)
                .map(([category, categoryRecipes]) => {
                  const key = query ? crypto.randomUUID() : category;
                  return (
                    <Collapsible title={category} key={key} defaultOpen>
                      <div className="grid sm:auto-rows-[minmax(0,141px)] md:auto-rows-[minmax(0,226px)] lg:auto-rows-[minmax(0,196px)] xl:auto-rows-[minmax(0,226px)] 2xl:auto-rows-[minmax(0,248px)] gap-6 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 mb-12 mt-6">
                        {categoryRecipes.map((recipe: ToolboxObject.Workshop) => {
                          return <RecipeLibraryTile recipe={recipe} key={`${recipe.id}${category}`} />;
                        })}
                      </div>
                    </Collapsible>
                  );
                })}
            </div>
          );
        }}
      </LoadingComponent>
    </div>
  );
};
