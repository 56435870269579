import React from 'react';

export const Mokojam = ({...props}) => {
  const {iswhite} = props;
  return (
    <svg id="Ebene_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 316.62 48.77" {...props}>
      <style>{`.cls-1{fill:${iswhite ? '#ffffff' : '#ec6467'};}.cls-2{fill:#fff;}.cls-3{fill:${iswhite ? '#ffffff' : '#ffdf69'};}.cls-4{fill:${
        iswhite ? '#ffffff' : '#8db9e4'
      };}`}</style>

      <g id="Ebene_1-2">
        <g>
          <path
            className="cls-4"
            d="M80.79,21.79L60.79,1.79c-1.24-1.24-3.25-1.24-4.49,0l-2.98,2.98,17.02,17.02c1.24,1.24,1.24,3.25,0,4.49l-17.02,17.02,2.98,2.98c1.24,1.24,3.25,1.24,4.49,0l20-20c1.24-1.24,1.24-3.25,0-4.49Z"
          />
          <path
            className="cls-1"
            d="M63.1,21.79L43.11,1.79c-1.24-1.24-3.25-1.24-4.49,0l-2.98,2.98,17.02,17.02c1.24,1.24,1.24,3.25,0,4.49l-17.02,17.02,2.98,2.98c1.24,1.24,3.25,1.24,4.49,0l20-20c1.24-1.24,1.24-3.25,0-4.49Z"
          />
          <rect className="cls-3" x={5.86} y={6.72} width={34.63} height={34.63} rx={3.17} ry={3.17} transform="translate(23.78 -9.35) rotate(45)" />
          <g>
            <path
              className="cls-2"
              d="M131.22,10.48c-4.01,0-6.83,1.9-8.27,4.16-1.64-2.62-4.47-4.16-7.86-4.16-3.08,0-5.44,1.13-7.04,2.67v-2.05h-4.88v25.88h5.14V20.8c0-2.93,2-5.86,6.01-5.86,3.18,0,4.98,2.47,4.98,6.21v15.82h5.14V20.8c0-2.93,2-5.86,6.01-5.86,3.18,0,4.98,2.47,4.98,6.21v15.82h5.14V20.13c0-5.8-4.01-9.65-9.35-9.65Z"
            />
            <path
              className="cls-2"
              d="M159.02,10.48c-7.34,0-13.3,5.44-13.3,13.56s5.96,13.56,13.3,13.56,13.3-5.44,13.3-13.56-5.96-13.56-13.3-13.56Zm0,22.7c-4.37,0-8.27-3.54-8.17-9.14,0-5.6,3.9-9.14,8.17-9.14s8.17,3.54,8.17,9.14-3.9,9.14-8.17,9.14Z"
            />
            <polygon
              className="cls-2"
              points="199.96 11.09 193.39 11.09 182.86 22.34 182.86 1.03 177.72 1.03 177.72 36.98 182.86 36.98 182.86 28.81 187.43 24.09 195.75 36.98 201.4 36.98 190.87 20.54 199.96 11.09"
            />
            <path
              className="cls-2"
              d="M215.73,10.48c-7.34,0-13.3,5.44-13.3,13.56s5.96,13.56,13.3,13.56,13.3-5.44,13.3-13.56-5.96-13.56-13.3-13.56Zm0,22.7c-4.37,0-8.27-3.54-8.17-9.14,0-5.6,3.9-9.14,8.17-9.14s8.17,3.54,8.17,9.14-3.9,9.14-8.17,9.14Z"
            />
            <path className="cls-2" d="M235.67,37.59c0,3.96-2.47,7.19-6.88,6.52v4.47c7.29,1.28,12.02-4.16,12.02-11.92V11.09h-5.14v26.5Z" />
            <path className="cls-2" d="M238.23,0c-2.05,0-3.59,1.54-3.59,3.54s1.54,3.6,3.59,3.6,3.54-1.54,3.54-3.6-1.54-3.54-3.54-3.54Z" />
            <path
              className="cls-2"
              d="M267.41,13.51c-2-1.95-4.67-3.03-7.96-3.03-7.34,0-13.3,5.44-13.3,13.56s5.96,13.56,13.3,13.56c3.29,0,5.96-1.08,7.96-3.03v2.41h5.14V11.09h-5.14v2.41Zm-7.96,19.67c-4.37,0-8.27-3.54-8.17-9.14,0-5.6,3.9-9.14,8.17-9.14s7.96,3.54,7.96,9.14-3.7,9.14-7.96,9.14Z"
            />
            <path
              className="cls-2"
              d="M307.27,10.48c-4.01,0-6.83,1.9-8.27,4.16-1.65-2.62-4.47-4.16-7.86-4.16-3.08,0-5.45,1.13-7.04,2.67v-2.05h-4.88v25.88h5.13V20.8c0-2.93,2.01-5.86,6.01-5.86,3.18,0,4.98,2.47,4.98,6.21v15.82h5.14V20.8c0-2.93,2-5.86,6.01-5.86,3.18,0,4.98,2.47,4.98,6.21v15.82h5.14V20.13c0-5.8-4.01-9.65-9.35-9.65Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
