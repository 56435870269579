import {useLocation} from 'react-router-dom';

export interface StateLocation extends Location {
  state: {
    routeFrom?: string;
    routeTo?: string;
    routeDelta?: number;
  };
}

export const useStateLocation = () => {
  const location = useLocation();
  location.state = location.state ?? {};
  return location as unknown as StateLocation;
};
