import {RichTypography, TextEditor} from 'components';
import {useOutsideEditorClick} from 'hooks';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Event from '@ckeditor/ckeditor5-utils/src/eventinfo';
import React, {useState} from 'react';

interface WorkshopDescriptionProps {
  initialValue: string;
  update: (text: string) => void;
}

export const WorkshopDescription: React.FC<WorkshopDescriptionProps> = ({initialValue, update}) => {
  const [editingValue, setEditingValue] = useState(initialValue);
  const [isEditing, setIsEditing] = useState(false);

  const onChange = (_: Event, editor: ClassicEditor) => {
    return setEditingValue(editor.getData());
  };

  const onBlur = () => {
    update(editingValue);
    setIsEditing(false);
  };
  const divRef = useOutsideEditorClick(onBlur);
  return (
    <div ref={divRef}>
      {isEditing ? (
        <TextEditor data={editingValue} onChange={onChange} />
      ) : (
        <RichTypography
          onClick={(event) => {
            event.stopPropagation();
            setIsEditing(true);
          }}
          text={editingValue}
          className="p-1 resize-y w-full h-full min-h-[144px] text-xs sm:text-sm leading-5 font-medium text-black rounded-md border border-black overflow-y-auto"
        />
      )}
    </div>
  );
};
