import React from 'react';

export const Checklist = ({...props}) => {
  return (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.16992 0.75C5.09704 0.75 4.18746 1.44913 3.87189 2.41667H2.83659C1.5019 2.41667 0.419922 3.49864 0.419922 4.83333V14.8333C0.419922 16.168 1.5019 17.25 2.83659 17.25H11.1699C12.5046 17.25 13.5866 16.168 13.5866 14.8333V4.83333C13.5866 3.49865 12.5046 2.41667 11.1699 2.41667H10.1346C9.81905 1.44913 8.90947 0.75 7.83659 0.75H6.16992ZM5.25325 3.16667C5.25325 2.66041 5.66366 2.25 6.16992 2.25H7.83659C8.34285 2.25 8.75325 2.66041 8.75325 3.16667C8.75325 3.67293 8.34285 4.08333 7.83659 4.08333H6.16992C5.66366 4.08333 5.25325 3.67293 5.25325 3.16667ZM3.41992 7.5C3.41992 7.08579 3.75571 6.75 4.16992 6.75H4.17826C4.59247 6.75 4.92826 7.08579 4.92826 7.5C4.92826 7.91421 4.59247 8.25 4.17826 8.25H4.16992C3.75571 8.25 3.41992 7.91421 3.41992 7.5ZM3.41992 10.5C3.41992 10.0858 3.75571 9.75 4.16992 9.75H4.17826C4.59247 9.75 4.92826 10.0858 4.92826 10.5C4.92826 10.9142 4.59247 11.25 4.17826 11.25H4.16992C3.75571 11.25 3.41992 10.9142 3.41992 10.5ZM4.16992 12.75C3.75571 12.75 3.41992 13.0858 3.41992 13.5C3.41992 13.9142 3.75571 14.25 4.16992 14.25H4.17826C4.59247 14.25 4.92826 13.9142 4.92826 13.5C4.92826 13.0858 4.59247 12.75 4.17826 12.75H4.16992ZM6.66992 12.75C6.25571 12.75 5.91992 13.0858 5.91992 13.5C5.91992 13.9142 6.25571 14.25 6.66992 14.25H9.91992C10.3341 14.25 10.6699 13.9142 10.6699 13.5C10.6699 13.0858 10.3341 12.75 9.91992 12.75H6.66992ZM6.66992 9.75C6.25571 9.75 5.91992 10.0858 5.91992 10.5C5.91992 10.9142 6.25571 11.25 6.66992 11.25H9.91992C10.3341 11.25 10.6699 10.9142 10.6699 10.5C10.6699 10.0858 10.3341 9.75 9.91992 9.75H6.66992ZM6.66992 6.75C6.25571 6.75 5.91992 7.08579 5.91992 7.5C5.91992 7.91421 6.25571 8.25 6.66992 8.25H9.91992C10.3341 8.25 10.6699 7.91421 10.6699 7.5C10.6699 7.08579 10.3341 6.75 9.91992 6.75H6.66992Z"
        fill="#000000"
      />
    </svg>
  );
};
