import React from 'react';
import {Form, Input} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import {Button, Modal} from 'components';
import styles from './CreateTranslationModal.module.scss';
import {ContentSave} from 'assets';
import {useCreateTranlsation} from 'services/query';
import {toast} from 'react-toastify';

export const CreateTranslationModal: React.FC<{isOpen: boolean; setIsOpen: (open: boolean) => void}> = ({isOpen, setIsOpen}) => {
  const [form] = Form.useForm<ToolboxObject.Translation>();
  const rules = [{required: true, message: 'Required'}];

  const [isSaveDisabled, setIsSaveDisabled] = React.useState(true);
  const {mutate: createTranslation, isLoading, isSuccess, error} = useCreateTranlsation();

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({errors}) => errors.length);
    setIsSaveDisabled(hasErrors);
  };

  React.useEffect(() => {
    if (isSuccess) {
      form.resetFields();
      setIsOpen(false);
    }
  }, [isSuccess]);

  const handleTranslationCreate = () => {
    const data = form.getFieldsValue();
    createTranslation(data);
  };

  React.useEffect(() => {
    if (error) {
      const hasStatus = typeof error === 'object' && 'status' in error;
      toast.error(hasStatus && error.status === 409 ? `Translation for '${form.getFieldValue('key')}' already exists` : 'Translation creation failed');
    }
  }, [error]);

  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <div className="flex justify-between items-center pr-10 h-12 mb-4">
        <h2 className={styles.heading}>Create new translation</h2>
        <Button disabled={isSaveDisabled || isLoading} onClick={handleTranslationCreate}>
          {isLoading ? <LoadingOutlined /> : <ContentSave fill="white" />}
          Create translation
        </Button>
      </div>
      <div className="overflow-y-auto h-[calc(100%-4rem)]">
        <Form form={form} layout="vertical" onFieldsChange={handleFormChange}>
          <Form.Item name="key" label="Key" rules={rules}>
            <Input />
          </Form.Item>
          <Form.Item name="en" label="English" rules={rules}>
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="de" label="German" rules={rules}>
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="fr" label="French" rules={rules}>
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="it" label="Itaian" rules={rules}>
            <Input.TextArea />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};
