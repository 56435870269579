import React from 'react';
import produce from 'immer';
import dayjs from 'dayjs';
import {useSelf} from 'services/query';
import {Card, LocalizedText} from 'components';
import {Draggable, Droppable} from '@hello-pangea/dnd';
import {WorkshopChecklistItem} from 'components/WorkshopChecklistItem/WorkshopChecklistItem';
import {WorkshopInfoItem} from './WorkshopInfoItem';

interface WorkshopInfoCardProps {
  type: string;
  infos: any[];
  setInfos: (value: any[]) => void;
  headerIcon: React.ReactNode;
}

export const WorkshopInfoCard: React.FC<WorkshopInfoCardProps> = ({type, headerIcon, infos, setInfos}) => {
  const isChecklist = type === 'Checklist';
  const {data: self} = useSelf();
  const firstName = self?.first_name || 'No owner';
  const [isAddedNew, setIsAddedNew] = React.useState(false);
  React.useEffect(() => {
    if (isAddedNew) {
      setIsAddedNew(false);
    }
  }, [isAddedNew]);
  return (
    <Card headerIcon={headerIcon} headerTitleKey={`pages.workshop.${type.toLowerCase()}s`} color="#E3E7EB">
      <Droppable droppableId={`${type}-list`} type={type.toUpperCase()}>
        {(provided) => {
          return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <div ref={provided.innerRef} {...provided.droppableProps} style={{overflow: 'hidden'}}>
              {infos.map((info, index) => {
                const draggableId = isChecklist ? JSON.stringify(info) + index : info + index;
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <Draggable draggableId={draggableId} index={index} key={draggableId}>
                    {(providedDraggable) => {
                      return (
                        <div
                          ref={providedDraggable.innerRef}
                          /* eslint-disable-next-line react/jsx-props-no-spreading */
                          {...providedDraggable.draggableProps}
                          /* eslint-disable-next-line react/jsx-props-no-spreading */
                          {...providedDraggable.dragHandleProps}
                          style={{...providedDraggable.draggableProps.style, position: 'static'}}
                        >
                          {isChecklist ? (
                            <WorkshopChecklistItem checklist={infos} checklistItem={info} index={index} update={(value: string[]) => setInfos(value)} />
                          ) : (
                            <WorkshopInfoItem
                              infos={infos}
                              info={info}
                              index={index}
                              type={type}
                              update={(value: string[]) => setInfos(value)}
                              initialEditing={isAddedNew && index === infos.length - 1}
                            />
                          )}
                        </div>
                      );
                    }}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        onClick={(event) => {
          event.stopPropagation();
          setIsAddedNew(true);
          return setInfos(
            produce(infos, (draft) => {
              // eslint-disable-next-line no-param-reassign
              if (type === 'Checklist') {
                draft.push({
                  description: 'New task',
                  username: firstName,
                  date: dayjs(),
                  status: 'new',
                });
              } else {
                draft.push('');
              }
            })
          );
        }}
      >
        <p className="cursor-pointer box-border text-xs sm:text-sm leading-5 font-medium w-auto text-gray-500 px-3 py-2 ml-[34px] hover:bg-white rounded-md border hover:border-gray-100 border-white mr-10 mt-2">
          <LocalizedText id={`pages.workshop.add${type}`} />
        </p>
      </div>
    </Card>
  );
};
