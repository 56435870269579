import React from 'react';
import {useTranslation} from 'react-i18next';

import {errorHandler} from 'services';
import {useDocumentTitle} from 'hooks';

export const ErrorPage: React.FC = () => {
  const {t} = useTranslation();

  useDocumentTitle('default');

  const error = errorHandler.getError();

  if (!error) {
    return <div>No error found</div>;
  }

  return (
    <div>
      <h2>Error Page</h2>
      <div>{t(`ErrorPage.errors.${error.errorPageTranslationKey ?? 'default'}`)}</div>
      <pre>{JSON.stringify(error.handlerError, null, '  ')}</pre>
    </div>
  );
};
