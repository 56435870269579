import React from 'react';

export const Download: React.FC = ({...props}) => {
  return (
    <svg width="16" height="16" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1 13.6523L1 14.6523C1 16.3092 2.34315 17.6523 4 17.6523L14 17.6523C15.6569 17.6523 17 16.3092 17 14.6523L17 13.6523M13 9.65234L9 13.6523M9 13.6523L5 9.65234M9 13.6523L9 1.65234"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
