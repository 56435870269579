import React from 'react';

export const DifficultyDots: React.FC = ({...props}) => {
  return (
    <svg width="22" height="7" viewBox="0 0 22 7" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="3" cy="3.53369" r="3" fill="black" />
      <circle cx="11" cy="3.53369" r="3" fill="black" />
      <circle cx="19" cy="3.53369" r="2.5" stroke="black" />
    </svg>
  );
};
