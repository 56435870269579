import React from 'react';

export const Calendar = ({...props}) => {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.66667 4.46615V1.13281M12.3333 4.46615V1.13281M4.83333 7.79948H13.1667M3.16667 16.1328H14.8333C15.7538 16.1328 16.5 15.3866 16.5 14.4661V4.46615C16.5 3.54567 15.7538 2.79948 14.8333 2.79948H3.16667C2.24619 2.79948 1.5 3.54567 1.5 4.46615V14.4661C1.5 15.3866 2.24619 16.1328 3.16667 16.1328Z"
        stroke="#1F2933"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
