import {useEffect, useState} from 'react';

type ScreenName = 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

type Media = Record<ScreenName | 'isMobile', boolean>;

// @see https://tailwindcss.com/docs/screens
const widths: Record<ScreenName, number> = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536,
};

const isMobileBelowWidth: ScreenName = 'md';

const subscribers = new Set<() => void>();

const media: Media = {
  sm: false,
  md: false,
  lg: false,
  xl: false,
  xxl: false,
  isMobile: false,
};

const calculate = () => {
  const windowWidth = window.innerWidth;
  let update = false;
  (Object.entries(widths) as Array<[ScreenName, number]>).forEach(([name, width]) => {
    const hasWidth = windowWidth >= width;
    if (hasWidth !== media[name]) {
      update = true;
    }
    media[name] = hasWidth;
  });
  media.isMobile = !media[isMobileBelowWidth];
  return update;
};

calculate();

window.addEventListener('resize', () => {
  if (calculate()) {
    subscribers.forEach((subscriber) => {
      subscriber();
    });
  }
});

export function useBreakpoints() {
  const [state, setState] = useState<Media>(media);

  useEffect(() => {
    const subscriber = () => {
      setState({...media});
    };
    subscribers.add(subscriber);
    return () => {
      subscribers.delete(subscriber);
    };
  }, []);

  return state;
}
