import React from 'react';
import {FormattedMessage, PrimitiveType} from 'react-intl';

export type LocalizedTextData = Record<string, PrimitiveType | React.ReactElement>;

type LocalizedTextProps = {
  id?: string;
  values?: LocalizedTextData;
  defaultMessage?: string;
};

export const LocalizedText = (props: LocalizedTextProps) => {
  return <FormattedMessage id={props.id} values={props.values} defaultMessage={props.defaultMessage} />;
};
