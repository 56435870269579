import React from 'react';

import {Description, Header, Methods} from './components';

import styles from './RecipeDetail.module.scss';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface RecipeDetailProps {
  recipe: ToolboxObject.Workshop;
  duration: number;
}

export const RecipeDetail: React.FC<RecipeDetailProps> = ({recipe, duration}) => {
  return (
    <section className="flex flex-col h-full">
      <Header recipe={recipe} duration={duration} />
      <div className={classNames(styles.wrapper, 'flex flex-col lg:flex-row w-full overflow-y-auto')}>
        {(!!recipe.benefits?.length || !!recipe.requirements?.length || recipe.description) && <Description recipe={recipe} />}
        <Methods recipe={recipe} />
      </div>
    </section>
  );
};
