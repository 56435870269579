import React from 'react';

export const PenAlt: React.FC = ({...props}) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.7336 1.10297C17.7984 0.16571 16.2823 0.16571 15.3472 1.10297L6.26493 10.2059V13.6H9.65132L18.7336 4.49708C19.6687 3.55982 19.6687 2.04023 18.7336 1.10297Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.278564 5.20002C0.278564 3.87454 1.35064 2.80002 2.67311 2.80002H7.4622C8.12343 2.80002 8.65947 3.33728 8.65947 4.00002C8.65947 4.66277 8.12343 5.20002 7.4622 5.20002H2.67311V17.2H14.6458V12.4C14.6458 11.7373 15.1819 11.2 15.8431 11.2C16.5043 11.2 17.0404 11.7373 17.0404 12.4V17.2C17.0404 18.5255 15.9683 19.6 14.6458 19.6H2.67311C1.35064 19.6 0.278564 18.5255 0.278564 17.2V5.20002Z"
        fill="black"
      />
    </svg>
  );
};
