import React from 'react';

export const DotsHorizontal: React.FC = ({...props}) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.99967 10C4.99967 10.4603 4.62658 10.8334 4.16634 10.8334C3.7061 10.8334 3.33301 10.4603 3.33301 10C3.33301 9.53978 3.7061 9.16669 4.16634 9.16669C4.62658 9.16669 4.99967 9.53978 4.99967 10Z"
        fill="black"
      />
      <path
        d="M10.833 10C10.833 10.4603 10.4599 10.8334 9.99967 10.8334C9.53944 10.8334 9.16634 10.4603 9.16634 10C9.16634 9.53978 9.53944 9.16669 9.99967 9.16669C10.4599 9.16669 10.833 9.53978 10.833 10Z"
        fill="black"
      />
      <path
        d="M16.6663 10C16.6663 10.4603 16.2932 10.8334 15.833 10.8334C15.3728 10.8334 14.9997 10.4603 14.9997 10C14.9997 9.53978 15.3728 9.16669 15.833 9.16669C16.2932 9.16669 16.6663 9.53978 16.6663 10Z"
        fill="black"
      />
      <path
        d="M4.16634 10H4.17467M9.99967 10H10.008M15.833 10H15.8413M4.99967 10C4.99967 10.4603 4.62658 10.8334 4.16634 10.8334C3.7061 10.8334 3.33301 10.4603 3.33301 10C3.33301 9.53978 3.7061 9.16669 4.16634 9.16669C4.62658 9.16669 4.99967 9.53978 4.99967 10ZM10.833 10C10.833 10.4603 10.4599 10.8334 9.99967 10.8334C9.53944 10.8334 9.16634 10.4603 9.16634 10C9.16634 9.53978 9.53944 9.16669 9.99967 9.16669C10.4599 9.16669 10.833 9.53978 10.833 10ZM16.6663 10C16.6663 10.4603 16.2932 10.8334 15.833 10.8334C15.3728 10.8334 14.9997 10.4603 14.9997 10C14.9997 9.53978 15.3728 9.16669 15.833 9.16669C16.2932 9.16669 16.6663 9.53978 16.6663 10Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
