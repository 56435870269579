import React from 'react';

import styles from './Card.module.scss';
import {LocalizedText} from 'components/LocalizedText';

interface CardProps {
  children: React.ReactNode;
  headerIcon: React.ReactNode;
  headerTitleKey: string;
  color?: string;
}

export const Card: React.FC<CardProps> = ({children, headerIcon, headerTitleKey, color = 'white'}) => {
  return (
    <div className={styles.methodCard}>
      <header className={styles.header} style={{backgroundColor: color}}>
        {headerIcon}
        <h2>
          <LocalizedText id={headerTitleKey} />
        </h2>
      </header>
      <div>{children}</div>
    </div>
  );
};
