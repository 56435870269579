import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Event from '@ckeditor/ckeditor5-utils/src/eventinfo';
import {Form, FormItemProps} from 'antd';
import React from 'react';

export const EditorFormItem: React.FC<React.PropsWithChildren<FormItemProps>> = ({children, ...props}) => {
  return (
    <Form.Item valuePropName="data" getValueFromEvent={(_: Event, editor: ClassicEditor) => editor.getData()} {...props}>
      {children}
    </Form.Item>
  );
};
