import axios, {AxiosError} from 'axios';

import {RoutePath} from 'Router';
import {utils} from 'services';

type Data = Record<string, unknown>;

interface ErrorExtensions {
  data?: Data;
  errorPageTranslationKey?: string;
}

interface HandleOptions {
  noErrorPage?: boolean;
  forceErrorPage?: boolean;
}

export interface ExtendedError extends Error, ErrorExtensions {
  handlerError: {
    currentUser?: string;
    selectedUser?: string;
    message: string;
    data?: Data;
    http?: {
      url?: string;
      method?: string;
      params?: Data;
      requestData?: Data;
      responseData?: unknown;
    };
  };
}

let error: ExtendedError | undefined;

export const errorHandler = {
  create(errorOrMessage: unknown | string, extensions: ErrorExtensions = {}) {
    const extendedError = (typeof errorOrMessage === 'string' ? new Error(errorOrMessage) : errorOrMessage) as ExtendedError;
    Object.entries(extensions).forEach(([name, value]) => {
      extendedError[name as keyof ErrorExtensions] = value;
    });
    return extendedError;
  },

  handle(thrownError: unknown, options: HandleOptions = {}) {
    const extendedError = thrownError as ExtendedError;
    extendedError.handlerError = {
      message: extendedError.message,
      data: extendedError.data,
    };
    if (extendedError instanceof axios.AxiosError) {
      const {config, response} = extendedError as AxiosError;
      extendedError.handlerError.http = config
        ? {
            url: config.url,
            method: config.method,
            params: config.params as Data,
            requestData: config.data ? (JSON.parse(config.data) as Data) : undefined,
            responseData: response?.data,
          }
        : {};
    } else {
      extendedError.message = JSON.stringify(extendedError.handlerError, null, '  ');
    }
    // eslint-disable-next-line no-console
    console.error(extendedError);
    error = extendedError;
    if (!options.noErrorPage) {
      utils.getNavigate()?.(`/en/${RoutePath.error}`);
    }
    return error;
  },

  getError() {
    return error;
  },
};
