import React from 'react';
import {upperFirst} from 'services';
import {useMethodSubcategories} from 'services/query';

export const useMethodFilters = () => {
  const {data: allSubcategories = []} = useMethodSubcategories();
  const categoryOptions = React.useMemo(() => {
    const categoryIdMap: Record<number, boolean> = {};
    return allSubcategories.reduce((acc: ToolboxObject.Category[], {category}) => {
      if (categoryIdMap[category.id]) {
        return acc;
      }
      categoryIdMap[category.id] = true;
      return acc.concat(category);
    }, []);
  }, [allSubcategories]);

  return React.useMemo(
    () => ({
      category: categoryOptions.map((category) => ({
        label: upperFirst(category.name),
        value: category.id.toString(),
      })),
      subcategory: allSubcategories.map((subcategory) => ({
        label: upperFirst(subcategory.name),
        value: subcategory.id.toString(),
        category: subcategory.category.id.toString(),
      })),
      socialForm: [
        {
          value: '1',
          label: '1 on 1',
        },
        {
          value: '2',
          label: 'Group',
        },
      ],
      format: [
        {
          label: 'Easy',
          value: '1',
        },
        {
          label: 'Medium',
          value: '2',
        },
        {
          label: 'Hard',
          value: '3',
        },
      ],
    }),
    [categoryOptions, allSubcategories]
  );
};
