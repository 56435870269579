import React from 'react';

export const People: React.FC = ({...props}) => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.00005 7.73359C9.32553 7.73359 10.4 6.65908 10.4 5.33359C10.4 4.00811 9.32553 2.93359 8.00005 2.93359C6.67456 2.93359 5.60005 4.00811 5.60005 5.33359C5.60005 6.65908 6.67456 7.73359 8.00005 7.73359Z"
        fill="black"
      />
      <path
        d="M4.80005 6.93359C4.80005 7.81725 4.0837 8.53359 3.20005 8.53359C2.31639 8.53359 1.60005 7.81725 1.60005 6.93359C1.60005 6.04994 2.31639 5.33359 3.20005 5.33359C4.0837 5.33359 4.80005 6.04994 4.80005 6.93359Z"
        fill="black"
      />
      <path
        d="M1.19256 12.7941C1.0569 12.7153 0.952219 12.591 0.906023 12.4411C0.837134 12.2174 0.800049 11.9798 0.800049 11.7336C0.800049 10.4081 1.87457 9.33359 3.20005 9.33359C3.61765 9.33359 4.01034 9.44025 4.35237 9.62781C3.51468 10.4527 2.95536 11.5596 2.82788 12.795C2.80949 12.9731 2.81716 13.1489 2.84802 13.3183C2.24854 13.266 1.68694 13.0815 1.19256 12.7941Z"
        fill="black"
      />
      <path
        d="M13.1524 13.3183C13.7518 13.266 14.3133 13.0815 14.8075 12.7941C14.9432 12.7153 15.0479 12.591 15.0941 12.4411C15.163 12.2174 15.2 11.9798 15.2 11.7336C15.2 10.4081 14.1255 9.33359 12.8 9.33359C12.3825 9.33359 11.9899 9.4402 11.648 9.62769C12.4857 10.4526 13.0451 11.5596 13.1726 12.795C13.1909 12.9731 13.1833 13.1489 13.1524 13.3183Z"
        fill="black"
      />
      <path
        d="M14.4 6.93359C14.4 7.81725 13.6837 8.53359 12.8 8.53359C11.9164 8.53359 11.2 7.81725 11.2 6.93359C11.2 6.04994 11.9164 5.33359 12.8 5.33359C13.6837 5.33359 14.4 6.04994 14.4 6.93359Z"
        fill="black"
      />
      <path
        d="M4.24312 13.4863C4.08383 13.3421 3.99943 13.1319 4.02148 12.9181C4.2293 10.9042 5.93131 9.33359 8.00016 9.33359C10.069 9.33359 11.771 10.9042 11.9788 12.9181C12.0009 13.1319 11.9165 13.3421 11.7572 13.4863C10.7637 14.3857 9.4459 14.9336 8.00017 14.9336C6.55443 14.9336 5.23665 14.3857 4.24312 13.4863Z"
        fill="black"
      />
    </svg>
  );
};
