import React from 'react';
import {ArrowRightCircleIcon} from '@heroicons/react/20/solid';
import {useLocalizedNavigation} from 'hooks';
import {LocalizedText} from 'components/LocalizedText';

interface MethodLibraryTileProps {
  image: string;
  textKey: string;
  nav: string;
}

export const HomeTile: React.FC<MethodLibraryTileProps> = ({image, textKey, nav}) => {
  const navigate = useLocalizedNavigation();
  return (
    <div
      className="w-full flex-1 flex rounded-xl bg-white w-full min-w-[322px] h-[289px] p-6 justify-center flex-col gap-8
    hover:shadow-popblue hover:mt-[-4px] hover:mb-[4px] hover:border-2 hover:border-homeblue
    transition-all font-['Inter'] group hover:cursor-pointer group"
      onClick={() => {
        return navigate(nav);
      }}
      aria-hidden
    >
      <img className="object-contain max-w-[80%] max-h-[80%] m-auto" src={image} alt={textKey} />
      <div className="flex justify-center items-center">
        <p className="text-2xl leading-[29px] font-black text-start">
          <LocalizedText id={textKey} />
        </p>
        <ArrowRightCircleIcon className="w-[2em] ml-auto invisible group-hover:visible" />
      </div>
    </div>
  );
};
