import React from 'react';

export const Duplicate = ({...props}) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M7 9C7 7.89543 7.89543 7 9 7H15C16.1046 7 17 7.89543 17 9V15C17 16.1046 16.1046 17 15 17H9C7.89543 17 7 16.1046 7 15V9Z"/>
<path d="M5 3C3.89543 3 3 3.89543 3 5V11C3 12.1046 3.89543 13 5 13L5 6C5 5.5 5.5 5 6 5C7 5 10.0437 5.05101 13 5C13 3.89543 12.1046 3 11 3H5Z"/>
    </svg>
  );
};
