import {FormInstance, UploadFile} from 'antd';
import {getMinutuesFromMs} from 'services/utils/date';
import {emptyAttachment} from '../components';

const formatFiles = (
  files: ToolboxObject.MethodFile[] = [],
  options: {usePreviewLink?: boolean; useDefaultPreview?: boolean} = {
    usePreviewLink: false,
    useDefaultPreview: false,
  }
): UploadFile[] => {
  return files.map((file) => {
    let url;
    if (!options.useDefaultPreview) {
      url = options.usePreviewLink ? file.preview_link : file.link;
    }
    return {
      uid: file.id,
      size: file.size,
      name: file.display_name,
      status: 'done',
      url,
    };
  });
};

const mapDynamicListOrDefault = (values?: {description?: string; name?: string}[] | null) => {
  const defaultValue = [''];
  if (!values) {
    return defaultValue;
  }
  const mappedValues = values.map(({description, name}) => description || name);
  return mappedValues.length ? mappedValues : defaultValue;
};

export const setInitialFormValues = (form: FormInstance<ToolboxObject.MethodDto>, method?: ToolboxObject.MethodDetails) => {
  form.setFieldValue('subcategory_id', method?.subcategory?.id);
  form.setFieldValue('title', method?.title || '');
  form.setFieldValue('description', method?.description || '');
  form.setFieldValue('duration', getMinutuesFromMs(method?.duration) || 10);
  form.setFieldValue('min_people', method?.min_people || 1);
  form.setFieldValue('difficulty', method?.difficulty || 1);
  form.setFieldValue('teaser', method?.teaser || '');
  form.setFieldValue('miro_href', method?.miro_href || undefined);

  form.setFieldValue('requirements', mapDynamicListOrDefault(method?.infos?.requirement));
  form.setFieldValue('results', mapDynamicListOrDefault(method?.infos?.result));
  form.setFieldValue('benefits', mapDynamicListOrDefault(method?.infos?.benefit));
  form.setFieldValue('preparations', mapDynamicListOrDefault(method?.steps?.preparation));
  form.setFieldValue('executions', mapDynamicListOrDefault(method?.steps?.execution));
  form.setFieldValue('materials', mapDynamicListOrDefault(method?.materials));
  form.setFieldValue('tips', mapDynamicListOrDefault(method?.infos?.tip));

  form.setFieldValue(['files', 'description'], formatFiles(method?.files?.description));
  form.setFieldValue(['files', 'icon_file'], method?.icon_file ? formatFiles([{...method?.icon_file, link: method?.iconLink || ''}]) : []);
  form.setFieldValue(['files', 'main_steps'], formatFiles(method?.files?.main_steps));
  form.setFieldValue(
    ['documents'],
    method?.documents?.map((document) => {
      return {
        file: formatFiles(document.file, {
          useDefaultPreview: true,
        }),
        preview: formatFiles(document.preview, {
          usePreviewLink: true,
        }),
        display_name: document.display_name,
        orderable: document.orderable,
      };
    }) || [emptyAttachment]
  );
};

export const getFileObjectsToRemove = (initialMethod: ToolboxObject.MethodDto, newMethod: ToolboxObject.MethodDto) => {
  const filesToRemove: string[] = [];
  const entries = Object.entries(initialMethod.files) as [keyof ToolboxObject.MethodDto['files'], ToolboxObject.MethodDtoFile[]][];
  for (const [key, files] of entries) {
    filesToRemove.push(
      ...files.reduce((acc: string[], file) => {
        return newMethod.files[key].some(({uid}) => file.uid === uid) ? acc : acc.concat(file.uid);
      }, [])
    );
  }
  const initialDocuments = initialMethod.documents.reduce((acc: string[], doc) => acc.concat(doc.file[0]?.uid, doc.preview[0]?.uid), []);
  const newDocuments = newMethod.documents.reduce((acc: string[], doc) => acc.concat(doc.file[0]?.uid, doc.preview[0]?.uid), []);

  filesToRemove.push(
    ...initialDocuments.reduce((acc: string[], fileId) => {
      return newDocuments.some((id) => fileId === id) ? acc : acc.concat(fileId);
    }, [])
  );
  return filesToRemove.filter(Boolean);
};

export const getFilesToUpload = (newMethod: ToolboxObject.MethodDto): File[] => {
  const filesToUpload: File[] = [];
  for (const files of Object.values(newMethod.files)) {
    filesToUpload.push(
      ...files.reduce((acc: File[], file) => {
        return file.originFileObj ? acc.concat(file.originFileObj) : acc;
      }, [])
    );
  }
  newMethod.documents.forEach((document) => {
    filesToUpload.push(
      ...[...document.preview, ...document.file].reduce((acc: File[], file) => {
        return file.originFileObj ? acc.concat(file.originFileObj) : acc;
      }, [])
    );
  });

  return filesToUpload;
};
