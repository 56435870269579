import React from 'react';

export const CheckedCheckbox = ({...props}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <rect x="0.5" y="0.5" width="19" height="19" rx="3.5" fill="#1F2933" />
      <rect x="0.5" y="0.5" width="19" height="19" rx="3.5" stroke="#1F2933" />
      <path
        d="M15.259 5.99123C15.4934 6.22564 15.625 6.54352 15.625 6.87498C15.625 7.20643 15.4934 7.52432 15.259 7.75873L9.00901 14.0087C8.7746 14.2431 8.45672 14.3747 8.12526 14.3747C7.79381 14.3747 7.47592 14.2431 7.24152 14.0087L4.74151 11.5087C4.51382 11.273 4.38782 10.9572 4.39067 10.6295C4.39352 10.3017 4.52498 9.98821 4.75674 9.75645C4.9885 9.52469 5.30202 9.39323 5.62976 9.39038C5.95751 9.38754 6.27326 9.51353 6.50901 9.74123L8.12526 11.3575L13.4915 5.99123C13.7259 5.75689 14.0438 5.62524 14.3753 5.62524C14.7067 5.62524 15.0246 5.75689 15.259 5.99123Z"
        fill="white"
      />
    </svg>
  );
};
