import React from 'react';

import styles from './SideBar.module.scss';

interface SideBarProps {
  children: React.ReactNode;
  color: string | undefined;
  icon: string | undefined;
  iconLink?: string;
}

export const SideBar: React.FC<SideBarProps> = ({children, color, icon, iconLink = ''}) => {
  return (
    <aside className={styles.sideBar} style={{backgroundColor: color}}>
      <img src={iconLink} width={125} alt={icon} />
      <div>{children}</div>
    </aside>
  );
};
