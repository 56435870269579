import {Progress, Spin} from 'antd';
import type {SpinProps} from 'antd/lib/spin';
import React, {useEffect, useRef, useState} from 'react';

interface FakeProgressLoaderProps extends SpinProps {
  loading: boolean;
}

let interval: string | number | NodeJS.Timer | undefined;
export const FakeProgressLoader: React.FC<FakeProgressLoaderProps> = (props) => {
  const {loading, children, ...resProps} = props;
  const [progress, setProgress] = useState(0);
  const currentProgress = useRef(0);
  const step = 0.1;

  useEffect(() => {
    if (loading && progress < 99) {
      interval = setInterval(() => {
        if (progress <= 99) {
          setProgress(() => {
            currentProgress.current += progress > 90 ? step / 10 : step;
            return (Math.atan(currentProgress.current / 3000) / (Math.PI / 2)) * 100;
          });
        } else {
          clearInterval(interval);
        }
      }, 100);
    } else {
      setProgress(99);
      clearInterval(interval);
    }
  }, [progress, loading]);

  return (
    <Spin
      style={{marginLeft: '-100px'}}
      spinning
      {...resProps}
      indicator={
        <Progress
          {...{
            percent: progress,
            type: 'circle',
            format: () => {
              return `${progress.toFixed(progress >= 90 ? 1 : 0)}%`;
            },
          }}
          strokeColor="black"
        />
      }
    />
  );
};
