export const DEFAULT_LANGUAGE = 'en';
export const SUPPORTED_LANGUAGES = ['en', 'de', 'fr', 'it'];

export enum RelativeRoutePath {
  root = '',
  error = 'error',
  list = 'list',
  library = 'library',
  workshopLibrary = 'workshops',
  workshopEditor = 'editor',
  login = 'login',
  register = 'register',
  resetPassword = 'reset/password',
  forgotPassword = 'forgot/password',
  adminDashboard = 'admin-dashboard',
  adminTranslation = 'admin-translations',
}
