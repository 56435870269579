import {NavigateFunction} from 'react-router-dom';

import {date, initDate} from './date';

let navigate: NavigateFunction | undefined;

export const utils = {
  date,

  setNavigate(navigateArg: NavigateFunction) {
    navigate = navigateArg;
  },

  getNavigate() {
    return navigate;
  },

  getEnumValue(enumObject: Record<string, string>, key: string) {
    return Object.keys(enumObject).includes(key) ? enumObject[key] : '';
  },
};

export const initUtils = async () => {
  await initDate();
};

export * from './helpers';
