import React, {useState} from 'react';
import {Button, LocalizedText, Modal} from 'components';
import {LinkIcon, LogoutIcon, SparklesIcon} from 'assets';

interface AddMiroModalProps {
  isOpen: boolean;
  onClose: () => void;
  hasMiro: boolean;
  createMiroBoard: () => void;
  linkMiroBoard: (href: string | null) => void;
}

export const AddMiroModal: React.FC<AddMiroModalProps> = ({isOpen, onClose, hasMiro, createMiroBoard, linkMiroBoard}) => {
  const [url, setUrl] = useState('');
  const onChangeUrl = (event: any) => {
    return setUrl(event.target.value);
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} className="sm:w-[500px] sm:h-fit sm:mx-auto">
      <div className="p-2">
        <h5 className="font-bold text-xl">
          <LocalizedText id="modals.addMiroBoard.title" />
        </h5>
        <div className="flex flex-col gap-8 mt-12">
          {!hasMiro ? (
            <div className="flex flex-col gap-4">
              <Button
                onClick={() => {
                  window.open(
                    `https://miro.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_MIRO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_MIRO_REDIRECT_URL}`
                  );
                  onClose();
                }}
                className="bg-gray-900"
              >
                <div className="flex items-center justify-center w-full gap-2">
                  <LogoutIcon />
                  <p>
                    1. <LocalizedText id="modals.addMiroBoard.connectToMiro" />
                  </p>
                </div>
              </Button>
              <Button onClick={() => {}} className="bg-gray-200 pointer-events-none">
                <div className="flex items-center justify-center w-full gap-2">
                  <SparklesIcon />
                  <p>
                    2. <LocalizedText id="modals.addMiroBoard.generateMiro" />
                  </p>
                </div>
              </Button>
            </div>
          ) : (
            <Button
              onClick={() => {
                createMiroBoard();
                onClose();
              }}
              className="bg-gray-900"
            >
              <div className="flex items-center justify-center w-full gap-2">
                <SparklesIcon />
                <p>
                  <LocalizedText id="modals.addMiroBoard.generateMiro" />
                </p>
              </div>
            </Button>
          )}
          <div className="flex items-center gap-2">
            <div className="flex-1 bg-gray-300 w-6 h-[1px]" />
            <p className="text-gray-500 text-sm">
              <LocalizedText id="modals.addMiroBoard.continueWith" />
            </p>
            <div className="flex-1 bg-gray-300 w-6 h-[1px]" />
          </div>
          <div className="flex flex-col gap-6">
            <div className="relative">
              <input
                type="text"
                aria-label="Field name"
                value={url}
                onChange={onChangeUrl}
                className="w-full border border-gray-200 transition-all rounded-md pl-1 focus:bg-transparent hover:bg-gray-50 placeholder:italic placeholder:text-gray-200 text-base mr-2"
                placeholder="Paste Url"
              />
              {!url && (
                <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                  <LinkIcon />
                </div>
              )}
            </div>
            <Button
              onClick={() => {
                if (url) {
                  linkMiroBoard(url);
                  onClose();
                }
              }}
            >
              <div className="flex items-center justify-center w-full gap-2">
                <LinkIcon />
                <p>
                  <LocalizedText id="modals.addMiroBoard.linkExistingMiro" />
                </p>
              </div>
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
