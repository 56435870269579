import {LocalizedText} from 'components';
import {useLocalizedNavigation} from 'hooks';
import React from 'react';
import {useIntl} from 'react-intl';
import {useLocation} from 'react-router-dom';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export interface NavTab {
  id: string;
  titleKey?: string;
  title?: string;
}

interface NavTabsProps {
  tabs: NavTab[];
  activeTab: string;
  setActiveTab: any;
  className?: string;
  disableNavigate?: boolean;
}

export const NavTabs: React.FC<NavTabsProps> = ({tabs, activeTab, setActiveTab, disableNavigate = false, className = ''}) => {
  const navigate = useLocalizedNavigation();
  const location = useLocation();
  const intl = useIntl();
  return (
    <div className={classNames(className, 'flex gap-8')}>
      {tabs.map((tab) => {
        return (
          <button
            type="button"
            key={tab.id}
            className={classNames(
              'text-sm leading-5 font-medium pb-4 focus:outline-none capitalize',
              activeTab === tab.id ? 'border-black border-b-2 mb:[-4px]' : 'text-black/[0.6] hover:text-black'
            )}
            onClick={() => {
              if (!disableNavigate) {
                const lastTabIndex = location.pathname.lastIndexOf('/');
                const newTabPath = `${location.pathname.slice(3, lastTabIndex + 1)}${intl.formatMessage({id: tab.id})}`;
                navigate(newTabPath, {replace: true});
              }
              setActiveTab(tab.id);
            }}
          >
            {tab.title || <LocalizedText id={tab.titleKey} />}
          </button>
        );
      })}
    </div>
  );
};
