import React, {useMemo, useState} from 'react';

import styles from './Methods.module.scss';
import {RecipeMethodLibraryTile} from '../../../LibraryTile/RecipeMethodLibraryTile';
import {NavTabs} from '../../../../pages/LibraryPage/NavTabs';
import {useIntl} from 'react-intl';

interface MethodsProps {
  recipe: ToolboxObject.Workshop;
}

export const Methods: React.FC<MethodsProps> = ({recipe}) => {
  const intl = useIntl();
  const getMethodDayName = (order: number) => `${intl.formatMessage({id: 'pages.workshop.methodDay'})} ${order + 1}`;

  const tabs = useMemo(() => {
    return recipe.sessions
      .map((session, index) => {
        return {
          id: (session.id || index).toString(),
          title: session.title || getMethodDayName(index + 1),
        };
      })
      .sort();
  }, [recipe.sessions]);
  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const filteredMethods = useMemo(() => {
    if (!tabs.length) {
      return [];
    }
    return recipe.sessions.filter((session) => {
      return activeTab === session.id?.toString();
    })[0].editable_methods;
  }, [tabs, recipe.sessions, activeTab]);
  return (
    <div className={styles.wrapper}>
      {tabs.length > 1 && <NavTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} disableNavigate className="w-full border-b border-solid" />}
      <div className={styles.methods}>
        {filteredMethods.map((method) => {
          const key = `${activeTab}.${method.order}`;
          return <RecipeMethodLibraryTile method={method} key={key} modalId={key} />;
        })}
      </div>
    </div>
  );
};
