import {config, initUtils, initI18n} from 'services';

export const initServices = async () => {
  // for testing
  window.debug = (...args: unknown[]) => {
    if (config.env.isDevelopment) {
      // eslint-disable-next-line no-console
      console.debug(...args);
    }
  };

  // for testing
  window.wait = (timeInMs?: number, condition = true) => {
    if (config.env.isDevelopment && condition) {
      return new Promise((resolve) => {
        setTimeout(resolve, timeInMs ?? 2000);
      });
    }
    return null;
  };

  await initUtils();
  await initI18n();
};

export * from './config';
export * from './i18n';
export * from './utils';
export * from './errorHandler';
export * from './axios';
