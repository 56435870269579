import React from 'react';

export const MokojamLogo = ({...props}) => {
  const {iswhite} = props;
  return (
    <svg id="Ebene_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85 48.77" {...props}>
      <defs>
        <style>{`.cls-1{fill:${iswhite ? '#ffffff' : '#ec6467'};}.cls-2{fill:#fff;}.cls-3{fill:${iswhite ? '#ffffff' : '#ffdf69'};}.cls-4{fill:${
          iswhite ? '#ffffff' : '#8db9e4'
        };}`}</style>
      </defs>
      <g>
        <path
          className="cls-4"
          d="M80.79,21.79L60.79,1.79c-1.24-1.24-3.25-1.24-4.49,0l-2.98,2.98,17.02,17.02c1.24,1.24,1.24,3.25,0,4.49l-17.02,17.02,2.98,2.98c1.24,1.24,3.25,1.24,4.49,0l20-20c1.24-1.24,1.24-3.25,0-4.49Z"
        />
        <path
          className="cls-1"
          d="M63.1,21.79L43.11,1.79c-1.24-1.24-3.25-1.24-4.49,0l-2.98,2.98,17.02,17.02c1.24,1.24,1.24,3.25,0,4.49l-17.02,17.02,2.98,2.98c1.24,1.24,3.25,1.24,4.49,0l20-20c1.24-1.24,1.24-3.25,0-4.49Z"
        />
        <rect className="cls-3" x={5.86} y={6.72} width={34.63} height={34.63} rx={3.17} ry={3.17} transform="translate(23.78 -9.35) rotate(45)" />
      </g>
    </svg>
  );
};
