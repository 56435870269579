import React, {useMemo, useState} from 'react';
import classNames from 'classnames';
import {MethodDetailContainer} from 'components/MethodDetail/MethodDetailContainer';

import {Modal, RecipeDetail} from 'components';
import {Description, Footer, Title} from '../components';
import {useLikeRecipe, useViewRecipe} from '../../../services/query';
import {useModal} from '../../../hooks';

import styles from './RecipeLibraryTile.module.scss';

interface RecipeLibraryTileProps {
  recipe: ToolboxObject.Workshop;
}

interface MethodSquareProps {
  method: ToolboxObject.EditableMethod;
  modalId: string;
  onHover: any;
}

export const MethodSquare: React.FC<MethodSquareProps> = ({method, onHover, modalId}) => {
  const {isOpen, close, open} = useModal('recipe_method_square', modalId);
  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    open();
  };
  return (
    <>
      {isOpen && (
        <Modal onClose={close} isOpen={isOpen}>
          <MethodDetailContainer id={method.original_method.id} />
        </Modal>
      )}
      <button
        type="button"
        aria-label={method.name}
        onClick={onClick}
        onFocus={onHover}
        onMouseOver={onHover}
        className="w-8 h-full hover:cursor-pointer"
        style={{backgroundColor: method.subcategory?.category.color}}
      />
    </>
  );
};

export const RecipeLibraryTile: React.FC<RecipeLibraryTileProps> = ({recipe}) => {
  // TODO
  const isComingSoon = !recipe.sessions.length;
  const {isOpen, close, open} = useModal('recipe', recipe.id.toString());
  const [hoveringMethod, setHoveringMethod] = useState<ToolboxObject.EditableMethod | undefined>(undefined);
  const recipeDuration = useMemo(() => {
    return recipe.sessions
      .flatMap((session) => {
        return session.editable_methods;
      })
      .reduce((sum, method) => {
        return sum + (method.duration ?? 0);
      }, 0);
  }, [recipe]);
  const {mutate: toggleLike} = useLikeRecipe(recipe.id, recipe.user_data?.is_favorite ?? false);
  const {mutate: view} = useViewRecipe(recipe.id);

  const onCard = () => {
    if (isComingSoon) {
      return;
    }
    open();
    view();
  };

  const onMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    open();
  };
  const onLike = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    toggleLike();
  };
  const onUnlike = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    toggleLike();
  };
  return (
    <>
      <Modal onClose={close} isOpen={isOpen}>
        <RecipeDetail recipe={recipe} duration={recipeDuration} />
      </Modal>
      <div className="group w-full">
        <div
          className="flex rounded-xl border-solid border border-black shadow-hard bg-white
    group-hover:shadow-pop group-hover:mt-[-2px] group-hover:mb-[2px] cursor-pointer
    transition-all font-['Inter'] group w-full h-full"
          onClick={onCard}
          aria-hidden
        >
          <div
            className={classNames(styles.methods, 'flex-none rounded-l-xl flex flex-col justify-between overflow-visible')}
            onMouseOut={() => {
              return setHoveringMethod(undefined);
            }}
            onBlur={() => {
              return setHoveringMethod(undefined);
            }}
          >
            {recipe.sessions
              .flatMap((session) => session.editable_methods)
              .filter((method) => {
                return !method.is_break;
              })
              .map((method, index) => {
                const key = `${recipe.id}.${method.original_method.id}.${index}.${method.order}`;
                return (
                  <MethodSquare
                    key={key}
                    modalId={key}
                    method={method}
                    onHover={() => {
                      return setHoveringMethod(method);
                    }}
                  />
                );
              })}
          </div>
          <div className="flex flex-col rounded-r-xl gap-3 px-4 py-3 justify-between w-full">
            <div className="flex flex-col gap-2 w-full">
              {hoveringMethod ? <Title text={hoveringMethod.title} color={hoveringMethod.subcategory?.category.color} /> : <Title text={recipe.title} />}
              {hoveringMethod ? <Description text={hoveringMethod.teaser ?? ''} /> : <Description text={recipe.teaser ?? ''} />}
            </div>
            {hoveringMethod ? (
              <Footer
                duration={hoveringMethod.duration}
                text={hoveringMethod.subcategory?.name}
                isLiked={!!recipe.user_data?.is_favorite}
                onLike={() => {}}
                hideExtras={!recipe.user_data?.is_favorite}
              />
            ) : (
              <Footer
                duration={recipeDuration}
                isLiked={!!recipe.user_data?.is_favorite}
                onMenu={onMenu}
                onUnlike={onUnlike}
                onLike={onLike}
                text={recipe.category?.name ?? 'recipe category'}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
