import React from 'react';

export const LightBulb: React.FC = ({...props}) => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.74219 1.39648C9.74219 0.8442 9.29447 0.396484 8.74219 0.396484C8.1899 0.396484 7.74219 0.8442 7.74219 1.39648V2.39648C7.74219 2.94877 8.1899 3.39648 8.74219 3.39648C9.29447 3.39648 9.74219 2.94877 9.74219 2.39648V1.39648Z"
        fill="black"
      />
      <path
        d="M14.399 4.15379C14.7895 3.76327 14.7895 3.1301 14.399 2.73958C14.0085 2.34905 13.3753 2.34905 12.9848 2.73958L12.2777 3.44668C11.8872 3.83721 11.8872 4.47037 12.2777 4.8609C12.6682 5.25142 13.3014 5.25142 13.6919 4.8609L14.399 4.15379Z"
        fill="black"
      />
      <path
        d="M16.7422 8.39648C16.7422 8.94877 16.2945 9.39648 15.7422 9.39648H14.7422C14.1899 9.39648 13.7422 8.94877 13.7422 8.39648C13.7422 7.8442 14.1899 7.39648 14.7422 7.39648H15.7422C16.2945 7.39648 16.7422 7.8442 16.7422 8.39648Z"
        fill="black"
      />
      <path
        d="M3.79238 4.86092C4.1829 5.25144 4.81607 5.25144 5.20659 4.86092C5.59711 4.47039 5.59711 3.83723 5.20659 3.4467L4.49948 2.73959C4.10896 2.34907 3.47579 2.34907 3.08527 2.73959C2.69474 3.13012 2.69475 3.76328 3.08527 4.15381L3.79238 4.86092Z"
        fill="black"
      />
      <path
        d="M3.74219 8.39648C3.74219 8.94877 3.29447 9.39648 2.74219 9.39648H1.74219C1.1899 9.39648 0.742187 8.94877 0.742188 8.39648C0.742188 7.8442 1.1899 7.39648 1.74219 7.39648H2.74219C3.29447 7.39648 3.74219 7.8442 3.74219 8.39648Z"
        fill="black"
      />
      <path
        d="M6.74219 14.3965V13.3965H10.7422V14.3965C10.7422 15.5011 9.84676 16.3965 8.74219 16.3965C7.63762 16.3965 6.74219 15.5011 6.74219 14.3965Z"
        fill="black"
      />
      <path
        d="M10.7431 12.3965C10.7577 12.0562 10.9498 11.7502 11.219 11.5376C12.1467 10.8051 12.7422 9.67032 12.7422 8.39648C12.7422 6.18735 10.9513 4.39648 8.74219 4.39648C6.53305 4.39648 4.74219 6.18735 4.74219 8.39648C4.74219 9.67032 5.33764 10.8051 6.26538 11.5376C6.53459 11.7502 6.7267 12.0562 6.74129 12.3965H10.7431Z"
        fill="black"
      />
    </svg>
  );
};
