import React from 'react';

export const Clock: React.FC = ({...props}) => {
  return (
    <svg width="14" height="18" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99961 13.2804C10.5342 13.2804 13.3996 10.415 13.3996 6.88037C13.3996 3.34575 10.5342 0.480371 6.99961 0.480371C3.46499 0.480371 0.599609 3.34575 0.599609 6.88037C0.599609 10.415 3.46499 13.2804 6.99961 13.2804ZM7.74961 2.88037C7.74961 2.46616 7.41382 2.13037 6.99961 2.13037C6.5854 2.13037 6.24961 2.46616 6.24961 2.88037V6.88037C6.24961 7.29458 6.5854 7.63037 6.99961 7.63037H10.1996C10.6138 7.63037 10.9496 7.29458 10.9496 6.88037C10.9496 6.46616 10.6138 6.13037 10.1996 6.13037H7.74961V2.88037Z"
        fill="black"
      />
    </svg>
  );
};
