import React from 'react';

export const ShoppingBag = ({...props}) => {
  return (
    <svg width="22" height="22" viewBox="0 0 24 24" stroke="black" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16 11V7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7V11M5 9H19L20 21H4L5 9Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
