import React from 'react';

export const UnlinkIcon = ({...props}) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.67362 11C7.7594 9.91421 6.43097 8.41421 5.2594 9.58578L3.67157 11.1738C2.10948 12.7359 2.10948 15.2685 3.67157 16.8306C5.23367 18.3927 7.76633 18.3927 9.32843 16.8306L10.8284 15.3306C11.219 14.9401 11.219 14.307 10.8284 13.9164C10.4379 13.5259 9.80474 13.5259 9.41421 13.9164L7.91421 15.4164C7.13316 16.1975 5.86684 16.1975 5.08579 15.4164C4.30474 14.6354 4.30474 13.3691 5.08579 12.588L6.67362 11Z"
        fill="white"
      />
      <path
        d="M14.5 8.82834C13.4142 9.91412 14.7426 11.4141 15.9142 10.2426L17.3284 8.82831C18.8905 7.26621 18.8905 4.73356 17.3284 3.17146C15.7663 1.60936 13.2337 1.60936 11.6716 3.17146L10.1716 4.67146C9.78105 5.06198 9.78105 5.69515 10.1716 6.08567C10.5621 6.47619 11.1953 6.47619 11.5858 6.08567L13.0858 4.58567C13.8668 3.80462 15.1332 3.80462 15.9142 4.58567C16.6953 5.36672 16.6953 6.63305 15.9142 7.4141L14.5 8.82834Z"
        fill="white"
      />
      <path d="M7.5 4.77777L7.5 2.99999" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M5.27783 6.92383L3.50005 6.92383" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M5.646 5.14606L4.38892 3.88898" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M13.5 15.1461L13.5 16.9238" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M15.7222 13L17.4999 13" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M15.354 14.7778L16.6111 16.0348" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};
