import React from 'react';

export const Flag: React.FC = ({...props}) => {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.0371094 3.53369C0.0371094 1.87684 1.38026 0.533691 3.03711 0.533691H13.0371C13.4159 0.533691 13.7621 0.747694 13.9315 1.08648C14.1009 1.42526 14.0644 1.83067 13.8371 2.13369L11.2871 5.53369L13.8371 8.93369C14.0644 9.23671 14.1009 9.64212 13.9315 9.9809C13.7621 10.3197 13.4159 10.5337 13.0371 10.5337H3.03711C2.48482 10.5337 2.03711 10.9814 2.03711 11.5337V14.5337C2.03711 15.086 1.58939 15.5337 1.03711 15.5337C0.484825 15.5337 0.0371094 15.086 0.0371094 14.5337V3.53369Z"
        fill="black"
      />
    </svg>
  );
};
