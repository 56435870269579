import React from 'react';

export const SparklesIcon: React.FC = ({...props}) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="heroicons-solid/sparkles">
        <g id="Vector">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.5 3.75C7.77905 3.75 8.02429 3.93499 8.10095 4.2033L8.77862 6.57512C9.07523 7.61327 9.88673 8.42477 10.9249 8.72138L13.2967 9.39905C13.565 9.47571 13.75 9.72095 13.75 10C13.75 10.2791 13.565 10.5243 13.2967 10.601L10.9249 11.2786C9.88673 11.5752 9.07523 12.3867 8.77862 13.4249L8.10095 15.7967C8.02429 16.065 7.77905 16.25 7.5 16.25C7.22095 16.25 6.97571 16.065 6.89905 15.7967L6.22138 13.4249C5.92477 12.3867 5.11327 11.5752 4.07512 11.2786L1.7033 10.601C1.43499 10.5243 1.25 10.2791 1.25 10C1.25 9.72095 1.43499 9.47571 1.7033 9.39905L4.07512 8.72138C5.11327 8.42477 5.92477 7.61327 6.22138 6.57512L6.89905 4.2033C6.97571 3.93499 7.22095 3.75 7.5 3.75Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 1.25C15.2868 1.25 15.5368 1.44519 15.6063 1.72342L15.8221 2.5863C16.018 3.37005 16.63 3.982 17.4137 4.17794L18.2766 4.39366C18.5548 4.46322 18.75 4.71321 18.75 5C18.75 5.28679 18.5548 5.53678 18.2766 5.60634L17.4137 5.82206C16.63 6.018 16.018 6.62995 15.8221 7.4137L15.6063 8.27658C15.5368 8.55481 15.2868 8.75 15 8.75C14.7132 8.75 14.4632 8.55481 14.3937 8.27658L14.1779 7.4137C13.982 6.62995 13.37 6.018 12.5863 5.82206L11.7234 5.60634C11.4452 5.53678 11.25 5.28679 11.25 5C11.25 4.71321 11.4452 4.46322 11.7234 4.39366L12.5863 4.17794C13.37 3.982 13.982 3.37005 14.1779 2.5863L14.3937 1.72342C14.4632 1.44519 14.7132 1.25 15 1.25Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.75 12.5C14.019 12.5 14.2579 12.6721 14.3429 12.9274L14.6715 13.913C14.7959 14.2862 15.0888 14.5791 15.462 14.7035L16.4476 15.0321C16.7029 15.1171 16.875 15.356 16.875 15.625C16.875 15.894 16.7029 16.1329 16.4476 16.2179L15.462 16.5465C15.0888 16.6709 14.7959 16.9638 14.6715 17.337L14.3429 18.3226C14.2579 18.5779 14.019 18.75 13.75 18.75C13.481 18.75 13.2421 18.5779 13.1571 18.3226L12.8285 17.337C12.7041 16.9638 12.4112 16.6709 12.038 16.5465L11.0524 16.2179C10.7971 16.1329 10.625 15.894 10.625 15.625C10.625 15.356 10.7971 15.1171 11.0524 15.0321L12.038 14.7035C12.4112 14.5791 12.7041 14.2862 12.8285 13.913L13.1571 12.9274C13.2421 12.6721 13.481 12.5 13.75 12.5Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  );
};
