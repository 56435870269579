import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import localeData from 'dayjs/plugin/localeData';
import minMax from 'dayjs/plugin/minMax';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

import {i18n, languages} from 'services';

export const date = {
  create() {
    return dayjs();
  },
};

export const initDate = async () => {
  dayjs.extend(isBetween);
  dayjs.extend(localeData);
  dayjs.extend(minMax);
  dayjs.extend(duration);
  dayjs.extend(relativeTime);

  await Promise.all(
    languages.map((language) => {
      return import(`dayjs/locale/${language}.js`);
    })
  );

  i18n.on('languageChanged', () => {
    dayjs.locale(i18n.language);
  });
};

export const humanizeMillis = (millis: number): string => {
  const parts = [];
  const dayjsDuration = dayjs.duration(millis);

  if (!dayjsDuration || dayjsDuration.toISOString() === 'P0D') return '';

  if (dayjsDuration.days() >= 1) {
    return dayjsDuration.humanize();
  }

  if (dayjsDuration.hours() >= 1) {
    const hours = Math.floor(dayjsDuration.hours());
    parts.push(`${hours} ${hours > 1 ? 'hours' : 'hour'}`);
  }

  if (dayjsDuration.minutes() >= 1) {
    const minutes = Math.floor(dayjsDuration.minutes());
    parts.push(`${minutes} ${minutes > 1 ? 'minutes' : 'minute'}`);
  }

  if (dayjsDuration.seconds() >= 1) {
    const seconds = Math.floor(dayjsDuration.seconds());
    parts.push(`${seconds} ${seconds > 1 ? 'seconds' : 'second'}`);
  }

  return parts.join(' ');
};

export const getMinutuesFromMs = (millis?: number | null): number => {
  if (!millis) {
    return 0;
  }
  return millis / 60 / 1000;
};
