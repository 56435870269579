import {RoutePath} from 'Router';
import {AxiosError, HttpStatusCode} from 'axios';
import {Loader} from 'components/Loader';
import React from 'react';
import {toast} from 'react-toastify';
import {useMethod} from 'services/query';
import {MethodDetail} from './MethodDetail';
import {useLocalizedNavigation} from 'hooks';
import {useIntl} from 'react-intl';

interface MethodDetailContainerProps {
  id: number;
}

export const MethodDetailContainer: React.FC<MethodDetailContainerProps> = ({id}) => {
  const intl = useIntl();
  const navigate = useLocalizedNavigation();
  const {data: method, isLoading, error} = useMethod(id);

  if (!method && error) {
    if ((error as AxiosError).status === HttpStatusCode.NotFound) {
      toast.error(intl.formatMessage({id: 'toast.getMethod.fail'}), {
        position: 'top-center',
        autoClose: 1000,
        theme: 'dark',
      });
    }
    navigate(RoutePath.workshopLibrary);
    return null;
  }

  if (isLoading) {
    return (
      <div className="flex w-full h-full">
        <Loader />
      </div>
    );
  }
  return method ? <MethodDetail method={method} /> : null;
};
