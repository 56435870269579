import React from 'react';

export const Pencil = ({...props}) => {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.5858 0.984712C11.3668 0.203664 12.6332 0.203664 13.4142 0.984712C14.1953 1.76576 14.1953 3.03209 13.4142 3.81314L12.6213 4.60603L9.79289 1.77761L10.5858 0.984712Z"
        fill="white"
      />
      <path d="M8.37868 3.19182L0 11.5705V14.3989H2.82842L11.2071 6.02025L8.37868 3.19182Z" fill="white" />
    </svg>
  );
};
