import {Button, Form, Input, Table} from 'antd';
import React from 'react';
import {columns} from './columns';
import {useDeleteTranlsation, useTranlsations, useUpdateTranlsation} from 'services/query';
import {Loader} from 'components';
import {Trash} from 'assets';

export const TranslationTable: React.FC<{search: string}> = ({search}) => {
  const [editableRow, setEditableRow] = React.useState<ToolboxObject.Translation>();

  const [form] = Form.useForm();

  const clearEditableRow = () => {
    setEditableRow(undefined);
    form.resetFields();
  };
  const toggleEditableRow = (row: ToolboxObject.Translation) => {
    if (editableRow?.id === row.id) {
      clearEditableRow();
      return;
    }
    setEditableRow(row);
  };

  const {data: translations, isLoading} = useTranlsations();
  const {mutate: updateTranslation, isLoading: isUpdateLoading, isSuccess: isUpdateSuccess} = useUpdateTranlsation();
  const {mutate: deleteTranslation, isLoading: isDeleteLoading} = useDeleteTranlsation();

  const handleRowSave = () => {
    if (editableRow) {
      const formValues = form.getFieldsValue();
      // Filter all untouched fields
      Object.keys(formValues).forEach((key) => formValues[key] === undefined && delete formValues[key]);
      const data: ToolboxObject.Translation = {...editableRow, ...formValues};
      updateTranslation(data);
    }
  };

  React.useEffect(() => {
    if (isUpdateSuccess) {
      clearEditableRow();
    }
  }, [isUpdateSuccess]);

  const filteredTranslations = translations?.filter((translation) => {
    return Object.values(translation).some((value: string) => value.toString().toLowerCase().includes(search.toLowerCase()));
  });
  return isLoading ? (
    <Loader />
  ) : (
    <Form form={form}>
      <Table
        columns={[
          ...columns.map((col) => ({
            ...col,
            render: (text: string, row: ToolboxObject.Translation, index: number) => {
              return row.id === editableRow?.id ? (
                <Form.Item name={col.dataIndex as string} rules={[{required: true, message: 'Required'}]}>
                  <Input.TextArea defaultValue={text} />
                </Form.Item>
              ) : col.render ? (
                col.render(text, row, index)
              ) : (
                text
              );
            },
          })),
          {
            title: 'Actions',
            align: 'center',
            width: 200,
            render: (row: ToolboxObject.Translation) => {
              return (
                <div className="flex gap-1 justify-center">
                  <Button type="default" onClick={() => toggleEditableRow(row)} disabled={isUpdateLoading}>
                    {editableRow?.id === row.id ? 'Cancel' : 'Edit'}
                  </Button>
                  {editableRow?.id === row.id && (
                    <Button type="primary" onClick={handleRowSave} loading={isUpdateLoading}>
                      {isUpdateLoading ? '' : 'Save'}
                    </Button>
                  )}
                </div>
              );
            },
          },
          {
            render: (row: ToolboxObject.Translation) => {
              return (
                <Button type="text" className="px-3 py-1" onClick={() => deleteTranslation(row.id)} loading={isDeleteLoading}>
                  <Trash fill="red" />
                </Button>
              );
            },
          },
        ]}
        dataSource={filteredTranslations}
      />
    </Form>
  );
};
