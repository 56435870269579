import Event from '@ckeditor/ckeditor5-utils/src/eventinfo';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import {EditorConfig} from '@ckeditor/ckeditor5-core';
import React from 'react';
import styles from './TextEditor.module.scss';

type TextEditorProps = {
  disabled?: boolean;
  data?: string;
  onBlur?: (event: Event, editor: ClassicEditor) => void;
  onChange?: (event: Event, editor: ClassicEditor) => void;
  plugins?: EditorConfig['plugins'];
  [key: string]: any;
};

const defaultPlagins = ['Bold', 'Link', 'Italic', 'Paragraph', 'List'];

export const TextEditor: React.FC<TextEditorProps> = ({plugins = defaultPlagins, ...rest}) => {
  return (
    <div className={styles.ckeditorTextEditor}>
      <CKEditor
        editor={ClassicEditor}
        config={{
          plugins,
          link: {
            addTargetToExternalLinks: true,
          },
        }}
        onReady={(editor) => editor.focus()}
        {...rest}
      />
    </div>
  );
};
