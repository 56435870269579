import React from 'react';

export const DocumentDownload = ({...props}) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10 8.33333V13.3333M10 13.3333L7.50001 10.8333M10 13.3333L12.5 10.8333M14.1667 17.5H5.83334C4.91286 17.5 4.16667 16.7538 4.16667 15.8333V4.16667C4.16667 3.24619 4.91286 2.5 5.83334 2.5H10.4882C10.7092 2.5 10.9211 2.5878 11.0774 2.74408L15.5893 7.25592C15.7455 7.4122 15.8333 7.62416 15.8333 7.84518V15.8333C15.8333 16.7538 15.0871 17.5 14.1667 17.5Z"
        stroke="#F5F7FA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
