import React from 'react';
import {useCreateWorkshop} from 'services/query';
import {toast} from 'react-toastify';
import {useLocation} from 'react-router-dom';
import {WorkshopTemplate} from './WorkshopTemplate';
import {useLocalizedNavigation} from 'hooks';
import {useIntl} from 'react-intl';

export const NewWorkshopPage: React.FC = () => {
  const intl = useIntl();

  const navigate = useLocalizedNavigation();
  const {state} = useLocation();

  const {mutate: create} = useCreateWorkshop();
  const handleSave = React.useCallback(
    (
      title: string,
      sessions: ToolboxObject.WorkshopSession[],
      description: string,
      requirements: string[],
      benefits: string[],
      checklist: ToolboxObject.ChecklistTask[],
      category: ToolboxObject.WorkshopCategory | null,
      teaser: string | null
    ) => {
      const data = {
        title,
        sessions,
        description,
        requirements,
        benefits,
        checklist,
        category,
        teaser,
      };
      create(data, {
        onSuccess: (savedWorkshop) => {
          toast.success(intl.formatMessage({id: 'toast.workshop.create.success'}), {
            position: 'top-center',
            autoClose: 1000,
            theme: 'dark',
          });
          return navigate(`/editor/${savedWorkshop.id}`);
        },
        onError: () => {
          return toast.error(intl.formatMessage({id: 'toast.workshop.create.fail'}), {
            position: 'top-center',
            autoClose: 1000,
            theme: 'dark',
          });
        },
      });
    },
    [create, navigate]
  );
  return (
    <WorkshopTemplate
      handleSave={handleSave}
      workshop={{
        sessions: state?.recipe_sessions ?? undefined,
        description: state?.recipe_description,
        benefits: state?.recipe_benefits,
        requirements: state?.recipe_requirements,
      }}
    />
  );
};
