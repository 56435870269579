import buildInfo from 'buildInfo.json';

const searchParams = new URLSearchParams(window.location.search);

export const config = {
  buildInfo,
  env: {
    isDevelopment: process.env.NODE_ENV === 'development',
    isProduction: process.env.NODE_ENV === 'production',
    appName: process.env.REACT_APP_APP_NAME ?? 'appName',
    appKey: process.env.REACT_APP_APP_KEY ?? 'appKey',
  },
  url: {
    showTranlationsKeys: searchParams.get('showTranslationKeys') === 'true',
  },
  constant: {
    apiDateFormat: 'YYYY-MM-DD',
    uiDateFormat: 'DD.MM.YYYY',
  },
};
