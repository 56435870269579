import React, {Fragment, useState} from 'react';
import {Listbox, Transition} from '@headlessui/react';
import {CheckIcon, ChevronUpDownIcon} from '@heroicons/react/20/solid';
import {LoadingComponent, useRecipeCategories} from '../../../services/query';
import {LocalizedText} from 'components';

interface WorkshopCategorySelectorProps {
  category: ToolboxObject.WorkshopCategory | null;
  setCategory: (categoryId: ToolboxObject.WorkshopCategory | null) => void;
}

export const WorkshopCategorySelector: React.FC<WorkshopCategorySelectorProps> = ({category, setCategory}) => {
  const query = useRecipeCategories();
  const {data: categories} = query;
  const [selectedCategory, setSelectedCategory] = useState(category ?? null);

  return (
    <LoadingComponent query={query}>
      {() => {
        return (
          <div className="cursor-pointer">
            <Listbox
              value={selectedCategory}
              onChange={(v: ToolboxObject.WorkshopCategory) => {
                setSelectedCategory(v);
                setCategory(v);
              }}
            >
              <div className="relative mt-1 max-w-[413px]">
                <Listbox.Button className="hover:bg-gray-50 cursor-pointer relative w-full rounded-lg bg-white py-2 pl-3 pr-10 text-left border-black border focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                  <span className={`block truncate ${category ? 'font-medium' : 'font-normal italic'}`}>
                    {selectedCategory?.name ?? <LocalizedText id="pages.workshop.selectCategory" />}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Listbox.Button>
                <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                  <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
                    {categories?.map((workshopCategory: ToolboxObject.WorkshopCategory) => (
                      <Listbox.Option
                        key={workshopCategory.id}
                        className={({active, selected}) =>
                          `relative cursor-pointer select-none py-2 pl-4 pr-10 ${active ? 'bg-gray-50 text-black' : 'text-gray-900'} ${
                            selected ? '!bg-gray-100 text-black' : ''
                          }`
                        }
                        value={workshopCategory}
                      >
                        {({selected}) => (
                          <>
                            {selected ? (
                              <span className="absolute inset-y-0 right-0 flex items-center pr-3 text-black">
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                            <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>{workshopCategory.name}</span>
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
        );
      }}
    </LoadingComponent>
  );
};
