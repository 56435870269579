import React, {HTMLProps} from 'react';
import DOMPurify from 'dompurify';
import styles from './RichTypography.module.scss';

interface RichTypographyProps extends HTMLProps<HTMLDivElement> {
  text: string;
}

export const RichTypography: React.FC<RichTypographyProps> = ({text, className, ...props}) => {
  const classNames = className ? `${styles.richTypographyText} ${className}` : styles.richTypographyText;
  return <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(text, {ADD_ATTR: ['target']})}} className={classNames} {...props} />;
};
