import React from 'react';

import {getMinutuesFromMs} from 'services/utils/date';
import {RichTypography} from 'components/RichTypography';
import {Clock, DifficultyDots, MiroColor, People} from 'assets';

import styles from './Header.module.scss';
import {LocalizedText} from 'components/LocalizedText';

interface HeaderProps {
  method: ToolboxObject.MethodDetails;
}

export const Header: React.FC<HeaderProps> = ({method}) => {
  return (
    <header className={styles.header}>
      <div className="flex grow flex-col items-start gap-2 w-full">
        <h1 className={styles.title}>
          <RichTypography text={method.title} />
        </h1>
        <div className="flex flex-row gap-4 flex-wrap w-full">
          <div className="flex flex-row flex-items-center">
            <Clock />
            {method.duration && <span className={styles.text}>{getMinutuesFromMs(method.duration)} min</span>}
          </div>
          <div className="flex items-center">
            <People />
            <span className={styles.text}>{method.min_people === 1 ? '1 : 1' : <LocalizedText id="pages.library.method.group" />}</span>
          </div>
          <div className="flex items-center">
            <DifficultyDots />
            <span className={styles.text}>
              {(method.difficulty === 1 && <LocalizedText id="pages.library.method.easy" />) ||
                (method.difficulty === 2 && <LocalizedText id="pages.library.method.medium" />) ||
                (method.difficulty === 3 && <LocalizedText id="pages.library.method.hard" />)}
            </span>
          </div>
        </div>
      </div>
      {method.miro_href && (
        <a
          href={method.miro_href}
          target="_blank"
          rel="noreferrer"
          className="flex flex-nowrap whitespace-nowrap flex-grow-0 items-center p-1 ml-[-4px] mr-2 rounded-md hover:bg-gray-100 cursor-pointer w-fit mb-auto"
        >
          <MiroColor />
          <p className={styles.text}>
            <LocalizedText id="pages.library.method.openInMiro" />
          </p>
        </a>
      )}
    </header>
  );
};
