import React, {useRef} from 'react';
import dayjs, {Dayjs} from 'dayjs';
import produce from 'immer';
import {EditableMethodLibraryTile} from '../../../components/LibraryTile/EditableMethodLibraryTile';
import {DraggableTile} from './DraggableTile';

interface WorkshopCreatorDraggableTileProps {
  index: number;
  draggableId: string;
  startDate: Dayjs | null;
  editableMethods: ToolboxObject.EditableMethod[];
  setEditableMethods: (methods: ToolboxObject.EditableMethod[]) => void;
}

export const WorkshopCreatorDraggableTile: React.FC<WorkshopCreatorDraggableTileProps> = ({
  index,
  draggableId,
  startDate,
  editableMethods,
  setEditableMethods,
}) => {
  const durationRef = useRef<HTMLDivElement>();
  const method = editableMethods[index];
  const endMethod = startDate?.add(
    dayjs.duration(
      editableMethods.slice(0, index + 1).reduce((accumulator, sumMethod) => {
        return accumulator + (sumMethod.duration ?? 0);
      }, 0)
    )
  );
  const startMethod = endMethod?.subtract(dayjs.duration(method.duration ?? 0));
  return (
    <DraggableTile
      key={draggableId}
      index={index}
      draggableId={draggableId}
      start={startMethod}
      end={endMethod}
      focusDuration={() => {
        if (durationRef.current) {
          durationRef.current.click();
        }
      }}
      onDelete={() => {
        return setEditableMethods(
          produce(editableMethods, (draft) => {
            // eslint-disable-next-line no-param-reassign
            draft.splice(index, 1);
          })
        );
      }}
      onDuplicate={() => {
        return setEditableMethods(
          produce(editableMethods, (draft) => {
            // eslint-disable-next-line no-param-reassign
            draft.splice(index, 0, {...editableMethods[index]});
          })
        );
      }}
    >
      <EditableMethodLibraryTile
        tileIndex={index}
        method={method}
        update={(updatedEditableMethod: ToolboxObject.EditableMethod | null) => {
          if (!updatedEditableMethod) {
            return setEditableMethods(
              produce(editableMethods, (draft) => {
                // eslint-disable-next-line no-param-reassign
                draft.splice(index, 1);
              })
            );
          }
          return setEditableMethods(
            produce(editableMethods, (draft) => {
              // eslint-disable-next-line no-param-reassign
              draft[index] = updatedEditableMethod;
            })
          );
        }}
        durationRef={durationRef}
      />
    </DraggableTile>
  );
};
