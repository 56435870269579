import produce from 'immer';
import {useCallback} from 'react';
import {WorkshopModificationState} from '../helpers';

export interface WorkshopCreatorResult {
  setEditableMethods(methods: ToolboxObject.EditableMethod[]): void;
}

export const useWorkshopCreator = (props: WorkshopModificationState): WorkshopCreatorResult => {
  const {sessions, setSessions, activeSession} = props;

  const setEditableMethods = useCallback(
    (newMethods: ToolboxObject.EditableMethod[]) => {
      setSessions(
        produce(sessions, (draft) => {
          draft[activeSession].editable_methods = newMethods;
        })
      );
    },
    [sessions, activeSession]
  );

  return {
    setEditableMethods,
  };
};
