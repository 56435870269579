import React, {useEffect, useRef, useState} from 'react';
import {Form, Modal, UploadFile} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import Upload, {RcFile, UploadProps} from 'antd/es/upload';
import {NamePath} from 'antd/es/form/interface';

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const getFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

interface UploaderProps extends Omit<UploadProps, 'name'> {
  name: NamePath;
  title: string;
}

export const Uploader: React.FC<UploaderProps> = ({title, name, accept, multiple = false, maxCount = 5}) => {
  const uploadRef = useRef<{fileList: UploadFile[]}>();
  const [hasChanges, setHasChanges] = useState(false);
  useEffect(() => {
    if (hasChanges) {
      setHasChanges(false);
    }
  }, [hasChanges]);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };

  const handleCancel = () => setPreviewOpen(false);
  const showUploadButton = !uploadRef.current || uploadRef.current.fileList.length < maxCount;
  return (
    <>
      <Form.Item label={title} name={name} valuePropName="fileList" getValueFromEvent={getFile}>
        <Upload
          beforeUpload={() => false}
          listType="picture-card"
          multiple={multiple}
          maxCount={maxCount}
          onPreview={handlePreview}
          onChange={() => setHasChanges(true)}
          accept={accept}
          ref={uploadRef}
        >
          {showUploadButton && (
            <div>
              <PlusOutlined />
              <div style={{marginTop: 8}}>Upload</div>
            </div>
          )}
        </Upload>
      </Form.Item>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{width: '100%'}} src={previewImage} />
      </Modal>
    </>
  );
};
