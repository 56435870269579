import dayjs, {Dayjs} from 'dayjs';
import produce from 'immer';
import {useCallback} from 'react';
import {WorkshopModificationState, defaultStartDate} from '../helpers';

export interface WorkshopFormResult {
  setDate: (value: Dayjs | null) => void;
  setStartTime: (value: Dayjs | null) => void;
}

export const useWorkshopForm = (props: WorkshopModificationState): WorkshopFormResult => {
  const {sessions, setSessions, activeSession} = props;
  const setStartTime = useCallback(
    (time: Dayjs | null) => {
      setSessions(
        produce(sessions, (draft) => {
          draft[activeSession].start = dayjs(sessions[activeSession].start ?? defaultStartDate)
            .set('hour', time?.hour() ?? 9)
            .set('minute', time?.minute() ?? 0)
            .toJSON();
        })
      );
    },
    [sessions, activeSession]
  );
  const setDate = useCallback(
    (date: Dayjs | null) => {
      setSessions(
        produce(sessions, (draft) => {
          draft[activeSession].start = dayjs(sessions[activeSession].start ?? defaultStartDate)
            .set('date', date?.date() ?? dayjs().date())
            .set('month', date?.month() ?? dayjs().month())
            .set('year', date?.year() ?? dayjs().year())
            .toJSON();
        })
      );
    },
    [sessions, activeSession]
  );

  return {
    setDate,
    setStartTime,
  };
};
