import React from 'react';

export const ExpandArrow = ({...props}) => {
  return (
    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.292893 0.794358C0.683417 0.403834 1.31658 0.403834 1.70711 0.794358L5 4.08725L8.29289 0.794358C8.68342 0.403834 9.31658 0.403834 9.70711 0.794358C10.0976 1.18488 10.0976 1.81805 9.70711 2.20857L5.70711 6.20857C5.31658 6.5991 4.68342 6.5991 4.29289 6.20857L0.292893 2.20857C-0.0976306 1.81805 -0.0976305 1.18488 0.292893 0.794358Z"
        fill="black"
        fillOpacity="0.7"
      />
    </svg>
  );
};
