import React from 'react';
import ReactDOM from 'react-dom/client';

import {initServices} from 'services';

import './styles/global.scss';
import './assets/main.css';

(async () => {
  await initServices();
  const {App} = await import('App');
  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<App />);
})();
