import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

export const useDocumentTitle = (key: string) => {
  const {t, i18n} = useTranslation();

  useEffect(() => {
    document.title = t(`DocumentTitle.${key}`, {
      defaultValue: t('DocumentTitle.default'),
    });
  }, [t, i18n.language, key]);
};
