import React, {useState} from 'react';
import produce from 'immer';
import dayjs from 'dayjs';
import {DatePicker, DatePickerProps} from 'antd';
import {useOutsideEditorClick} from 'hooks';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Event from '@ckeditor/ckeditor5-utils/src/eventinfo';
import {RichTypography, TextEditor} from 'components';
import {Checkbox} from 'components/Checkbox';
import {Trash, DragStep, Calendar} from '../../assets';

interface WorkshopChecklistItemProps {
  checklist: ToolboxObject.ChecklistTask[];
  checklistItem: ToolboxObject.ChecklistTask;
  index: number;
  update: any;
}

const dateFormat = 'DD.MM.YYYY';

export const WorkshopChecklistItem: React.FC<WorkshopChecklistItemProps> = ({checklist, checklistItem, index, update}) => {
  const [taskDescription, setTaskDescription] = useState(checklistItem.description);
  const [taskOwner, setTaskOwner] = useState(checklistItem.username);
  const [taskDate, setTaskDate] = useState(dayjs(checklistItem.date));
  const [taskStatus, setTaskStatus] = useState(checklistItem.status);

  const [isEditingTaskDescription, setIsEditingTaskDescription] = useState(false);
  const [isEditingTaskOwner, setIsEditingTaskOwner] = useState(false);

  const onChangeTaskStatus = () => {
    const selectedStatus = taskStatus === 'done' ? 'new' : 'done';
    setTaskStatus(selectedStatus);
    update(
      produce(checklist, (draft) => {
        draft[index].status = selectedStatus;
      })
    );
  };

  const onChangeTaskDescription = (_: Event, editor: ClassicEditor) => {
    return setTaskDescription(editor.getData());
  };

  const onChangeTaskOwner = (_: Event, editor: ClassicEditor) => {
    return setTaskOwner(editor.getData());
  };

  const onChangeTaskDate: DatePickerProps['onChange'] = (date) => {
    if (date) {
      setTaskDate(date);
      update(
        produce(checklist, (draft) => {
          draft[index].date = date.toISOString();
        })
      );
    }
  };

  const onDescriptionBlur = () => {
    setIsEditingTaskDescription(false);
    if (taskDescription.trim() === '') {
      setTaskDescription(checklistItem.description || 'New task');
    } else {
      update(
        produce(checklist, (draft) => {
          draft[index].description = taskDescription;
        })
      );
    }
  };

  const onOwnerBlur = () => {
    setIsEditingTaskOwner(false);
    if (taskOwner.trim() === '') {
      setTaskOwner(checklistItem.username || 'No owner');
    } else {
      update(
        produce(checklist, (draft) => {
          draft[index].username = taskOwner;
        })
      );
    }
  };

  const descriptionRef = useOutsideEditorClick(onDescriptionBlur);
  const ownerRef = useOutsideEditorClick(onOwnerBlur);

  const isTaskFinished = taskStatus === 'done';
  return (
    <div className="px-1 rounded-md flex gap-1 sm:gap-2 items-stretch text-xs sm:text-sm leading-5 font-medium text-opacity-70 w-full h-auto group/info">
      <div className="flex items-center justify-center w-6 h-8 bg-gray-50 sm:bg-white sm:group-hover/info:bg-gray-200 rounded-md">
        <DragStep className="w-5 h-5 self-center text-gray-900 sm:group-hover/info:text-gray-900" />
      </div>

      <div
        className="w-3/5 h-full hover:bg-white rounded-md outline outline-1 hover:outline-gray-100 outline-white outline-offset-[-1px] mr-7"
        ref={descriptionRef}
      >
        {isEditingTaskDescription ? (
          <TextEditor data={taskDescription} onChange={onChangeTaskDescription} />
        ) : (
          <RichTypography
            onClick={(event) => {
              event.stopPropagation();
              setIsEditingTaskDescription(true);
            }}
            text={taskDescription}
            className={`w-full h-full resize-none px-1 py-2 min-h-[32px] focus:outline-none leading-4 ${isTaskFinished && 'line-through'}`}
          />
        )}
      </div>
      <div className="w-[20%] h-full rounded-md mr-7" ref={ownerRef}>
        {isEditingTaskOwner ? (
          <TextEditor data={taskOwner} onChange={onChangeTaskOwner} />
        ) : (
          <RichTypography
            onClick={(event) => {
              event.stopPropagation();
              setIsEditingTaskOwner(true);
            }}
            text={taskOwner}
            className={`w-full h-full resize-none px-1 py-2 min-h-[32px] focus:outline-none leading-4 ${isTaskFinished && 'line-through'}`}
          />
        )}
      </div>
      <div className="w-[15%] min-w-[132px] h-full hover:bg-white rounded-md outline outline-1 hover:outline-gray-100 outline-white outline-offset-[-1px] mr-7">
        <DatePicker
          value={dayjs(taskDate)}
          onChange={onChangeTaskDate}
          suffixIcon={<Calendar />}
          clearIcon={null}
          format={dateFormat}
          className={`${isTaskFinished ? 'line-through' : ''}`}
          style={{
            height: '32px',
            border: 'none',
            borderRadius: '0px',
            boxShadow: 'none',
            cursor: 'pointer',
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            background: 'none',
          }}
        />
      </div>
      <div className="flex w-[10%] h-[32px] items-center justify-center mr-8">
        <Checkbox value={isTaskFinished} onChange={onChangeTaskStatus} />
      </div>
      <div className="flex h-full items-start justify-center invisible group-hover/info:visible pr-1 relative">
        <button
          type="button"
          className="hidden w-8 h-8 justify-center items-center aspect-square rounded-md bg-deletered group-hover/info:flex hover:bg-opacity-70 transition-colors absolute right-0"
          onClick={(event) => {
            event.stopPropagation();
            return update(
              produce(checklist, (draft) => {
                draft.splice(index, 1);
              })
            );
          }}
        >
          <Trash className="w-3.5 h-4 mx-auto" fill="white" fillOpacity="1" />
        </button>
      </div>
    </div>
  );
};
