import React, {MouseEventHandler} from 'react';
import {isMobile} from 'react-device-detect';
import {EmptyHeart, FullHeart, Timer} from '../../../assets';
import {humanizeMillis} from '../../../services/utils/date';

interface FooterProps {
  duration: number | undefined | null;
  text: string | undefined;
  capitalizeText?: boolean;
  hideExtras?: boolean;
  isLiked?: boolean;
  onMenu?: MouseEventHandler | undefined;
  onLike?: MouseEventHandler | undefined;
  onUnlike?: MouseEventHandler | undefined;
}

export const Footer: React.FC<FooterProps> = ({
  duration,
  text,
  capitalizeText = true,
  hideExtras = false,
  isLiked = false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onMenu = undefined,
  onLike = undefined,
  onUnlike = undefined,
}) => {
  return (
    <div className="flex gap-1 justify-start items-center text-center text-gray-600 text-[10px] sm:text-[12px] pb-1 pt-2 leading-3 font-medium">
      <div className="flex gap-1 flex-wrap text-start">
        {!!duration && (
          <div className="flex gap-1">
            <Timer fill="#00000099" preserveAspectRatio="xMidYMin slice" />
            <p>{humanizeMillis(duration)}</p>
          </div>
        )}
        {text && (
          <>
            {!!duration && <p>∙</p>}
            <p className={capitalizeText ? 'capitalize' : ''}>{text}</p>
          </>
        )}
      </div>
      {!hideExtras && (
        <div className="ml-auto mt-auto flex gap-4 items-center ">
          {/*
          TODO re-enable dots, when they have a function
          <button className={isMobile ? 'visible' : 'invisible group-hover:visible'} type="button" onClick={onMenu}>
            <MenuDots />
          </button>
          */}
          {isLiked ? (
            <button type="button" onClick={onUnlike}>
              <FullHeart />
            </button>
          ) : (
            <button className={isMobile ? 'visible' : 'invisible group-hover:visible'} type="button" onClick={onLike}>
              <EmptyHeart />
            </button>
          )}
        </div>
      )}
    </div>
  );
};
