import React from 'react';

export const DragStep = ({...props}) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="#1F2933" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="8" cy="5" r="1" />
      <circle cx="12" cy="5" r="1" />
      <circle cx="8" cy="10" r="1" />
      <circle cx="12" cy="10" r="1" />
      <circle cx="8" cy="15" r="1" />
      <circle cx="12" cy="15" r="1" />
    </svg>
  );
};
