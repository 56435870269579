import React, {useState} from 'react';
import {libraryTabs} from 'constants/library.constants';

import {useDocumentTitle} from 'hooks';
import {NavTabs} from './NavTabs';
import {MethodLibrary} from './MethodLibrary';
import {RecipeLibrary} from './RecipeLibrary';
import {Favorites} from './Favorites';
import {LocalizedText, SearchInput} from '../../components';

const renderSwitch = (tab: string, query: string | undefined) => {
  switch (tab) {
    case 'methods':
      return <MethodLibrary query={query} />;
    case 'favorites':
      return <Favorites query={query} />;
    default:
      return <RecipeLibrary query={query} />;
  }
};

interface LibraryPageProps {
  defaultTab?: string;
}

export const LibraryPage: React.FC<LibraryPageProps> = ({defaultTab = 'recipes'}) => {
  useDocumentTitle('library');

  const [query, setQuery] = useState<string>('');
  const [activeTab, setActiveTab] = useState<string>(defaultTab);

  return (
    <div>
      <div className="flex flex-col px-4 sm:px-6 lg:px-24 py-12 top-16 gap-4 max-w-[1600px] m-auto">
        <p className="text-2xl font-extrabold text-gray-900">
          <LocalizedText id="pages.library.pageTitle" />
        </p>
        <div className="flex justify-between items-start w-full border-black/[0.6] border-b mb-2">
          <div className="flex flex-col-reverse sm:flex-row justify-between w-full flex-wrap gap-6">
            <NavTabs tabs={libraryTabs} activeTab={activeTab} setActiveTab={setActiveTab} />
            <SearchInput query={query} setQuery={setQuery} className="mb-4" />
          </div>
        </div>
        {renderSwitch(activeTab, query)}
      </div>
    </div>
  );
};
