import React from 'react';

export const LogoutIcon: React.FC = ({...props}) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="Logout">
        <path
          id="Icon"
          d="M14.6667 13.3334L18 10M18 10L14.6667 6.66671M18 10L6.33333 10M11.3333 13.3334V14.1667C11.3333 15.5474 10.214 16.6667 8.83333 16.6667H5.5C4.11929 16.6667 3 15.5474 3 14.1667V5.83337C3 4.45266 4.11929 3.33337 5.5 3.33337H8.83333C10.214 3.33337 11.3333 4.45266 11.3333 5.83337V6.66671"
          stroke="#F5F7FA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
