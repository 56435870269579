import {useEffect, useRef} from 'react';

export const useOutsideEditorClick = (callback: () => void) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const editorWrapper = document.querySelector('.ck-body-wrapper');
      const target = event.target as Node;
      if (!ref?.current?.contains(target) && !editorWrapper?.contains(target)) {
        callback();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback]);

  return ref;
};
