import React from 'react';
import {SearchInput, Button} from 'components';
import {CreateTranslationModal, TranslationTable} from './components';

export const AdminTranslationPage: React.FC = () => {
  const [search, setSearch] = React.useState('');
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div>
      <div className="flex flex-col px-4 sm:px-6 lg:px-24 py-12 top-16 gap-4 max-w-[1600px] m-auto">
        <p className="text-2xl font-extrabold text-gray-900">Translations</p>
        <div className="flex justify-between items-center">
          <SearchInput query={search} setQuery={setSearch} />
          <Button onClick={() => setIsOpen(true)}>+ Create New</Button>
        </div>
        <TranslationTable search={search} />
      </div>
      <CreateTranslationModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};
