import React from 'react';

export const Briefcase = ({...props}) => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4.39893V3.39893C4 1.74207 5.34315 0.398926 7 0.398926H9C10.6569 0.398926 12 1.74207 12 3.39893V4.39893H14C15.1046 4.39893 16 5.29436 16 6.39893V9.96969C13.5096 10.8937 10.8149 11.3989 8.00002 11.3989C5.18514 11.3989 2.49037 10.8937 0 9.96967V6.39893C0 5.29436 0.895431 4.39893 2 4.39893H4ZM6 3.39893C6 2.84664 6.44772 2.39893 7 2.39893H9C9.55228 2.39893 10 2.84664 10 3.39893V4.39893H6V3.39893ZM7 8.39893C7 7.84664 7.44772 7.39893 8 7.39893H8.01C8.56229 7.39893 9.01 7.84664 9.01 8.39893C9.01 8.95121 8.56229 9.39893 8.01 9.39893H8C7.44772 9.39893 7 8.95121 7 8.39893Z"
        fill="white"
      />
      <path
        d="M0 12.0912V14.3989C0 15.5035 0.895431 16.3989 2 16.3989H14C15.1046 16.3989 16 15.5035 16 14.3989V12.0912C13.4872 12.9394 10.7964 13.3989 8.00002 13.3989C5.20363 13.3989 2.51279 12.9394 0 12.0912Z"
        fill="white"
      />
    </svg>
  );
};
