import React from 'react';

export const ClipBoardCheck: React.FC = ({...props}) => {
  return (
    <svg width="17" height="21" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.51245 3.21191C5.51245 2.10734 6.40788 1.21191 7.51245 1.21191H9.51245C10.617 1.21191 11.5125 2.10734 11.5125 3.21191C11.5125 4.31648 10.617 5.21191 9.51245 5.21191H7.51245C6.40788 5.21191 5.51245 4.31648 5.51245 3.21191Z"
        fill="black"
      />
      <path
        d="M6.21956 11.5048C5.82903 11.1143 5.19587 11.1143 4.80534 11.5048C4.41482 11.8953 4.41482 12.5285 4.80534 12.919L6.21956 11.5048ZM7.51245 14.2119L6.80534 14.919C7.19587 15.3095 7.82903 15.3095 8.21956 14.919L7.51245 14.2119ZM12.2196 10.919C12.6101 10.5285 12.6101 9.89533 12.2196 9.50481C11.829 9.11428 11.1959 9.11428 10.8053 9.50481L12.2196 10.919ZM14.5125 5.21191V17.2119H16.5125V5.21191H14.5125ZM13.5125 18.2119H3.51245V20.2119H13.5125V18.2119ZM2.51245 17.2119V5.21191H0.512451V17.2119H2.51245ZM3.51245 4.21191H5.51245V2.21191H3.51245V4.21191ZM11.5125 4.21191H13.5125V2.21191H11.5125V4.21191ZM3.51245 18.2119C2.96017 18.2119 2.51245 17.7642 2.51245 17.2119H0.512451C0.512451 18.8688 1.8556 20.2119 3.51245 20.2119V18.2119ZM14.5125 17.2119C14.5125 17.7642 14.0647 18.2119 13.5125 18.2119V20.2119C15.1693 20.2119 16.5125 18.8688 16.5125 17.2119H14.5125ZM16.5125 5.21191C16.5125 3.55506 15.1693 2.21191 13.5125 2.21191V4.21191C14.0647 4.21191 14.5125 4.65963 14.5125 5.21191H16.5125ZM2.51245 5.21191C2.51245 4.65963 2.96017 4.21191 3.51245 4.21191V2.21191C1.8556 2.21191 0.512451 3.55506 0.512451 5.21191H2.51245ZM4.80534 12.919L6.80534 14.919L8.21956 13.5048L6.21956 11.5048L4.80534 12.919ZM8.21956 14.919L12.2196 10.919L10.8053 9.50481L6.80534 13.5048L8.21956 14.919ZM7.51245 2.21191H9.51245V0.211914H7.51245V2.21191ZM9.51245 4.21191H7.51245V6.21191H9.51245V4.21191ZM7.51245 4.21191C6.96017 4.21191 6.51245 3.7642 6.51245 3.21191H4.51245C4.51245 4.86877 5.8556 6.21191 7.51245 6.21191V4.21191ZM10.5125 3.21191C10.5125 3.7642 10.0647 4.21191 9.51245 4.21191V6.21191C11.1693 6.21191 12.5125 4.86877 12.5125 3.21191H10.5125ZM9.51245 2.21191C10.0647 2.21191 10.5125 2.65963 10.5125 3.21191H12.5125C12.5125 1.55506 11.1693 0.211914 9.51245 0.211914V2.21191ZM7.51245 0.211914C5.8556 0.211914 4.51245 1.55506 4.51245 3.21191H6.51245C6.51245 2.65963 6.96017 2.21191 7.51245 2.21191V0.211914Z"
        fill="black"
      />
    </svg>
  );
};
