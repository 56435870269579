import React, {useState} from 'react';
import produce from 'immer';
import {TextEditor} from 'components/TextEditor';
import {RichTypography} from 'components/RichTypography';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Event from '@ckeditor/ckeditor5-utils/src/eventinfo';
import {useOutsideEditorClick} from 'hooks';
import {Trash, DragStep} from '../../../assets';

interface EditableMethodStepProps {
  method: ToolboxObject.EditableMethod;
  step: string;
  index: number;
  update: any;
}

export const EditableMethodStep: React.FC<EditableMethodStepProps> = ({method, step, index, update}) => {
  const [editingValue, setEditingValue] = useState(step);
  const [isEditing, setIsEditing] = useState(false);

  const onChange = (_: Event, editor: ClassicEditor) => {
    return setEditingValue(editor.getData());
  };

  const onBlur = () => {
    setIsEditing(false);
    if (editingValue.trim() === '') {
      setEditingValue(step);
    } else {
      update(
        produce(method, (draft) => {
          draft.steps[index] = editingValue;
        })
      );
    }
    setIsEditing(false);
  };
  const divRef = useOutsideEditorClick(onBlur);
  return (
    <div className="rounded-md flex items-center gap-1 sm:gap-2 text-xs sm:text-sm leading-5 font-medium text-opacity-70 w-full h-auto group/step" ref={divRef}>
      <div className="flex items-center justify-center w-6 h-8 bg-gray-50 rounded-md">
        <DragStep className="w-5 h-5 text-gray-300" />
      </div>
      <div className="w-full h-full px-3 py-2 group-hover/step:bg-white rounded-md border group-hover/step:border-gray-100 border-white">
        {isEditing ? (
          <TextEditor data={editingValue} onChange={onChange} />
        ) : (
          <RichTypography
            key={step}
            onClick={(event) => {
              event.stopPropagation();
              setIsEditing(true);
            }}
            className="w-full"
            text={`${index + 1}. ${editingValue}`}
          />
        )}
      </div>
      <div className="flex h-full items-start justify-center pr-1 relative">
        <button
          type="button"
          className="flex w-6 h-6 justify-center items-center aspect-square rounded-md bg-deletered opacity-0 hover:bg-opacity-70 transition-colors group-hover/step:opacity-100"
          onClick={(event) => {
            event.stopPropagation();
            setIsEditing(false);
            return update(
              produce(method, (draft) => {
                draft.steps.splice(index, 1);
              })
            );
          }}
        >
          <Trash className="w-3.5 h-3.5" fill="white" fillOpacity="1" />
        </button>
      </div>
    </div>
  );
};
