import React, {useState} from 'react';
import {Button, LocalizedText, Modal} from 'components';
import {WorkshopCategorySelector} from './WorkshopCategorySelector';
import {RecipeTeaser} from './RecipeTeaser';

interface RecipeFieldsModalProps {
  isOpen: boolean;
  onClose: () => void;
  exportAsRecipe: (category: ToolboxObject.WorkshopCategory | null, teaser: string | null) => void;
}

export const RecipeFieldsModal: React.FC<RecipeFieldsModalProps> = ({isOpen, onClose, exportAsRecipe}) => {
  const [category, setCategory] = useState<ToolboxObject.WorkshopCategory | null>(null);
  const [teaser, setTeaser] = useState<string | null>(null);
  return (
    <Modal isOpen={isOpen} onClose={onClose} className="sm:w-[500px] sm:h-fit sm:mx-auto">
      <div className="h-full flex gap-6 flex-col">
        <h5 className="font-bold text-xl">
          <LocalizedText id="pages.workshop.saveAsRecipe" />
        </h5>

        <div>
          <p className="text-sm">
            <LocalizedText id="pages.workshop.category" />
          </p>
          <WorkshopCategorySelector category={category} setCategory={setCategory} />
        </div>
        <div>
          <p className="text-sm">
            <LocalizedText id="pages.workshop.teaserDescription" />
          </p>
          <RecipeTeaser initialTeaser={teaser} updateTeaser={setTeaser} />
        </div>
        <div className="h-fit flex gap-2 flex-row-reverse w-full">
          <Button
            onClick={() => {
              exportAsRecipe(category, teaser);
              onClose();
            }}
          >
            <LocalizedText id="common.save" />
          </Button>
          <Button
            onClick={() => {
              onClose();
            }}
            className="bg-white !text-black border !border-black hover:bg-gray-50"
          >
            <LocalizedText id="common.cancel" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};
