import React from 'react';
import {Button} from 'components/Button';
import {DocumentAdd, DocumentDownload, Trash} from 'assets';
import produce from 'immer';
import {useDeleteMethodAttachments, useUploadMethodAttachments} from 'services/query';
import {Loader, Modal} from 'components';
import {getFileUrl} from 'services';

interface EditableMethodAttachmentsProps {
  method: ToolboxObject.EditableMethod;
  update: any;
  attachments?: ToolboxObject.IFile[];
}

interface EditableMethodAttachmentProps {
  attachment: ToolboxObject.IFile;
  handleClick(): void;
  handleDelete: React.MouseEventHandler<HTMLButtonElement>;
}

const EditableMethodAttachment: React.FC<EditableMethodAttachmentProps> = ({attachment, handleClick, handleDelete}) => {
  return (
    <div className="group/attachment relative" title={attachment.original_name}>
      <Button className="max-w-[150px]" onClick={handleClick}>
        <DocumentDownload className="min-w-[20px]" />
        <span className="text-ellipsis overflow-hidden whitespace-nowrap">{attachment.original_name}</span>
      </Button>
      <button
        type="button"
        className="flex w-5 h-5 justify-center items-center aspect-square rounded-full
    bg-[#D1483F] opacity-0 hover:bg-deletered
    absolute top-[-9px] right-[-9px] group-hover/attachment:opacity-100"
        onClick={handleDelete}
      >
        <Trash className="w-3 h-3" fill="white" fillOpacity="1" />
      </button>
    </div>
  );
};

export const EditableMethodAttachments: React.FC<EditableMethodAttachmentsProps> = ({method, attachments = [], update}) => {
  const {mutateAsync: uploadFiles} = useUploadMethodAttachments();
  const {mutate: deleteFiles} = useDeleteMethodAttachments();

  const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (!event.target.files) {
      return;
    }
    const formData = new FormData();
    [...event.target.files].forEach((file) => formData.append('files[]', file));
    if (method.id) {
      formData.set('methodId', method.id.toString());
    }
    uploadFiles(formData).then((files = []) => {
      update(
        produce(method, (draft) => {
          draft.attachments = [...(draft.attachments || []), ...files];
        })
      );
    });
  };

  const inputFileRef = React.useRef<React.ElementRef<'input'>>(null);
  const [previewUrl, setPreviewUrl] = React.useState<string>();
  const [previewFileId, setPreviewFileId] = React.useState<string>();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isPreviewLoading, setIsPreviewLoading] = React.useState(false);

  const handleClose = () => {
    setIsOpen(false);
    setPreviewUrl(undefined);
    setPreviewFileId(undefined);
  };

  const handlePreview = (id: string) => {
    setIsPreviewLoading(true);
    getFileUrl(id)
      .then(setPreviewUrl)
      .finally(() => setIsPreviewLoading(false));
    setIsOpen(true);
    setPreviewFileId(id);
  };

  const handleDownload = (id: string) => {
    const downloadLink = `${process.env.REACT_APP_TOOLBOX_HOST}/files/${id}/download`;
    const handler = window.open(downloadLink);
    handler?.blur();
    window.focus();
  };

  const handleAttachmentClick = (attachment: ToolboxObject.IFile) => () => {
    if (attachment.mimetype?.startsWith('image')) {
      handlePreview(attachment.id);
      return;
    }
    handleDownload(attachment.id);
  };

  const handleDelete: (attachment: ToolboxObject.IFile) => React.MouseEventHandler<HTMLButtonElement> = (attachment) => (event) => {
    event.stopPropagation();
    update(
      produce(method, (draft) => {
        draft.attachments = (draft.attachments || []).filter(({id}) => attachment.id !== id);
      })
    );
    deleteFiles([attachment.id.toString()]);
  };
  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose} className="md:max-w-[60vw] mx-auto">
        {isPreviewLoading ? (
          <Loader />
        ) : (
          <div className="flex flex-col gap-3 items-center h-full">
            <div className="shadow-md">
              <img src={previewUrl} alt="" className="w-full" />
            </div>
            <Button className="max-w-[150px] absolute bottom-6" onClick={previewFileId ? () => handleDownload(previewFileId) : undefined}>
              <DocumentDownload className="min-w-[20px]" />
              Download
            </Button>
          </div>
        )}
      </Modal>
      <div className="flex gap-3 flex-wrap">
        {attachments.map((attachment) => (
          <EditableMethodAttachment
            key={attachment.id}
            handleClick={handleAttachmentClick(attachment)}
            attachment={attachment}
            handleDelete={handleDelete(attachment)}
          />
        ))}
        <div className="border border-black rounded-md">
          <input hidden type="file" ref={inputFileRef} onChange={handleFileChange} multiple />
          <Button className="focus:outline-none focus:ring-0 bg-white hover:bg-gray-50 whitespace-nowrap" onClick={() => inputFileRef.current?.click()}>
            <DocumentAdd />
            <span className="text-black">Add files</span>
          </Button>
        </div>
      </div>
    </>
  );
};
