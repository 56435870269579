import React from 'react';
import {Form, Button, Row, Input, Switch, Col} from 'antd';
import {PlusOutlined, MinusCircleOutlined} from '@ant-design/icons';
import {Uploader} from '../Uploader';
import styles from '../../AdminPage.module.scss';
import {NamePath} from 'antd/es/form/interface';

interface AttachmentsProps {
  name: NamePath;
  title: string;
}

export const emptyAttachment = {file: [], preview: [], display_name: '', orderable: true};

export const Attachments: React.FC<AttachmentsProps> = ({name, title}) => {
  return (
    <Form.List name={name}>
      {(fields, {add, remove}, {errors}) => {
        return (
          <>
            {fields.map((field, index) => (
              <Form.Item label={index === 0 ? title : ''} key={field.key}>
                <Row>
                  <Uploader name={[field.name, 'file']} title="File" maxCount={1} />
                  <Uploader name={[field.name, 'preview']} title="Preview file" maxCount={1} accept="image/*" />
                  <Col style={{maxWidth: 400, width: '100%'}}>
                    <Form.Item label="Display name" name={[field.name, 'display_name']} style={{marginBottom: '12px'}}>
                      <Input placeholder="Display name" allowClear />
                    </Form.Item>
                    <Form.Item label="Orderable" name={[field.name, 'orderable']} valuePropName="checked">
                      <Switch />
                    </Form.Item>
                  </Col>
                  {fields.length > 1 ? <MinusCircleOutlined className={styles.dynamicDeleteButton} onClick={() => remove(field.name)} /> : null}
                </Row>
              </Form.Item>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => add(emptyAttachment)} style={{width: '80%'}} icon={<PlusOutlined />}>
                Add new
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        );
      }}
    </Form.List>
  );
};
