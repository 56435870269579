import React from 'react';
import {MethodDetailContainer} from 'components/MethodDetail/MethodDetailContainer';
import {Modal} from '../../Modal';
import {Description, Footer, Title} from '../components';
import {useLikeMethod} from '../../../services/query';
import {useModal} from '../../../hooks';

interface MethodLibraryTileProps {
  method: ToolboxObject.Method;
  stateModal?: boolean;
  nested?: boolean;
  handleClick?(id: number): void;
}

export const MethodLibraryTile: React.FC<MethodLibraryTileProps> = ({method, nested = false, stateModal = false, handleClick = undefined}) => {
  const {mutate: toggleLike} = useLikeMethod(method.id, method.user_data?.is_favorite ?? false);
  const {isOpen, close, open} = useModal('method', method.id.toString(), stateModal);

  const handleCardClick = () => {
    if (method.is_break) {
      return;
    }
    open();
  };
  const onMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    open();
  };
  const onLike = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    toggleLike();
  };
  const onUnlike = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    toggleLike();
  };
  return (
    <>
      <Modal onClose={close} isOpen={isOpen} nested={nested}>
        <MethodDetailContainer id={method.id} />
      </Modal>
      <div className="group w-full">
        <div
          className="flex rounded-xl border-solid border border-black shadow-hard bg-white
    group-hover:shadow-pop group-hover:mt-[-2px] group-hover:mb-[2px] cursor-pointer
    transition-all font-['Inter'] w-full h-full break-words"
          onClick={handleClick ? () => handleClick(method.id) : handleCardClick}
          aria-hidden
        >
          {method.iconLink && (
            <div style={{backgroundColor: method.subcategory?.category.color}} className="flex-none rounded-l-xl">
              <img src={method.iconLink} alt={method.icon} width={65} />
            </div>
          )}
          <div className="flex flex-col rounded-r-xl gap-3 px-4 py-3 w-full justify-between">
            <div className="flex flex-col gap-2 w-full">
              <Title text={method.title} />
              {method.teaser && <Description text={method.teaser} />}
            </div>
            {!nested && (
              <Footer
                isLiked={!!method.user_data?.is_favorite}
                duration={method.duration}
                onMenu={onMenu}
                onUnlike={onUnlike}
                onLike={onLike}
                text={method.subcategory?.name}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
