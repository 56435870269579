export const libraryTabs = [
  {id: 'recipes', titleKey: 'pages.library.recipesTab'},
  {id: 'methods', titleKey: 'pages.library.methodsTab'},
  {id: 'favorites', titleKey: 'pages.library.favoritesTab'},
];
export const adminLibraryTabs = [
  {
    id: 'methods',
    titleKey: 'pages.admin.methodsTab',
  },
  {
    id: 'draft methods',
    titleKey: 'pages.admin.draftMethodsTab',
  },
];
