import React from 'react';
import {UseQueryResult} from '@tanstack/react-query';
import {Pulse} from '../Pulse';
import {Loader} from '../Loader';

interface LoadingComponentProps {
  children: () => React.ReactElement;
  query: UseQueryResult<any, unknown>;
  pulse?: boolean;
}

export const LoadingComponent: React.FC<LoadingComponentProps> = ({children, query, pulse = false}) => {
  const {isLoading, isFetching, isError} = query;
  if (isError) {
    // Todo better error component
    return <p>Error</p>;
  }
  if (isLoading || isFetching) {
    return pulse ? <Pulse /> : <Loader />;
  }
  return children();
};
