import React, {useRef, useState} from 'react';
import produce from 'immer';

// @ts-ignore
import InputNumber from 'react-input-number';
import {Timer} from '../../../assets';
import {humanizeMillis} from '../../../services/utils/date';

interface EditableMethodDurationProps {
  method: ToolboxObject.EditableMethod;
  update: any;
  durationRef: any;
}

export const EditableMethodFooter: React.FC<EditableMethodDurationProps> = ({method, update, durationRef}) => {
  const [editingValue, setEditingValue] = useState((method.duration ?? 0) / 60000);
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef(null);

  const onKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.target.blur();
    } else if (event.key === 'Escape') {
      setEditingValue((method.duration ?? 0) / 60000);
      event.target.blur();
    }
  };
  const onBlur = (event: any) => {
    setIsEditing(false);
    if (event.target.value.trim() === '' || event.target.value.trim() === '0') {
      setEditingValue((method.duration ?? 0) / 60000);
    } else {
      update(
        produce(method, (draft) => {
          // eslint-disable-next-line no-param-reassign
          draft.duration = +editingValue * 60000;
        })
      );
    }
  };
  // const onChange = (event: any) => {
  //   if (event.target.value.trim() === '' || event.target.value.trim() === '0') {
  //     setEditingValue((method.duration ?? 0)/60000);
  // }
  return (
    <div className="text-gray-500 text-[10px] sm:text-[12px] leading-3 font-medium pt-1 pl-3">
      {isEditing ? (
        <div className="flex items-center relative w-fit pl-3">
          <InputNumber
            /* eslint-disable-next-line jsx-a11y/no-autofocus */
            autoFocus
            ref={inputRef}
            onChange={setEditingValue}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            className="w-20 h-full resize-none p-1 mt-1 pr-8"
            value={editingValue}
          />
          <p className="absolute right-0 mr-2 pointer-events-none text-center mt-1.5">min</p>
        </div>
      ) : (
        <div className="flex gap-1 flex-wrap text-start text-center items-center">
          <div
            ref={durationRef}
            className="flex gap-1 p-1 mx-[-4px] rounded-lg cursor-pointer border hover:border-gray-100 hover:text-black border-white pl-3"
            onClick={(event) => {
              event.stopPropagation();
              setIsEditing(true);
              if (inputRef.current) {
                // @ts-ignore
                inputRef.current.focus();
              }
            }}
            aria-hidden
          >
            <Timer fill="#00000099" preserveAspectRatio="xMidYMin slice" />
            <p>{humanizeMillis(method.duration ?? 0)}</p>
          </div>
          {method.subcategory?.name && (
            <>
              <p>∙</p>
              <p className="capitalize">{method.subcategory?.name}</p>
            </>
          )}
        </div>
        // <p
        //   onClick={(event) => {
        //     event.stopPropagation();
        //     setIsEditing(true);
        //     if (inputRef.current) {
        //       // @ts-ignore
        //       inputRef.current.focus();
        //     }
        //   }}
        //   className="w-full px-1 rounded-sm flex gap-2 items-center text-xs leading-5 font-medium text-opacity-70 w-full hover:bg-gray-200"
        // >
        //   {editingValue}
        // </p>
      )}
    </div>
  );
};
