import {useSearchParams} from 'react-router-dom';
import {useState} from 'react';

export interface ModalControl {
  isOpen: boolean;
  close: () => void;
  open: () => void;
}

export const useModal = (modalType: string, modalId: string, stateOnly = false) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramMatch = searchParams.get(modalType) === modalId;
  const [isOpen, setIsOpen] = useState(stateOnly ? false : paramMatch);
  const open = () => {
    setIsOpen(true);
    if (!stateOnly) {
      searchParams.set(modalType, modalId);
      setSearchParams(searchParams);
    }
  };
  const close = () => {
    setIsOpen(false);
    if (!stateOnly) {
      searchParams.delete(modalType);
      setSearchParams(searchParams);
    }
  };
  return {isOpen, open, close} as ModalControl;
};
