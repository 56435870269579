import produce from 'immer';

import {UseQueryOptions, useMutation, useQuery} from '@tanstack/react-query';
import {QueryClient} from '@tanstack/query-core';
import {createSyncStoragePersister} from '@tanstack/query-sync-storage-persister';
import {MethodStatus} from 'types';
import {
  createMethod,
  createRecipe,
  createWorkshop,
  deleteMethod,
  deleteRecipe,
  deleteWorkshop,
  disconnectMiro,
  getMethod,
  getMethods,
  getRecipe,
  getRecipes,
  getSelf,
  getMethodSubcategories,
  getRecipeCategories,
  getWorkshop,
  getWorkshops,
  linkMiroBoardToWorkshop,
  miroBoardFromWorkshop,
  saveRecipe,
  saveWorkshop,
  toggleLikeMethod,
  toggleLikeRecipe,
  updateMethod,
  viewMethod,
  viewRecipe,
  viewWorkshop,
  invite,
  uploadMethodAttachments,
  deleteMethodAttachments,
  getTranslations,
  updateTranslation,
  createTranslation,
  deleteTranslation,
} from '../axios';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

export const useMethods = (statuses?: MethodStatus[]) => {
  return useQuery<ToolboxObject.Method[]>(
    ['methods', {statuses}],
    () => {
      return getMethods(statuses).then((data) => data.sort((a, b) => a.name.localeCompare(b.name)));
    },
    {staleTime: 3600000, cacheTime: 3600000}
  );
};

export const useMethod = (id: number, options: UseQueryOptions<ToolboxObject.MethodDetails> = {}) => {
  return useQuery<ToolboxObject.MethodDetails>(
    ['methods', {id}],
    () => {
      return getMethod(id);
    },
    {staleTime: 300000, ...options} // 5 minutes
  );
};

export const useCreateMethod = () => {
  return useMutation({
    mutationFn: (data: FormData) => {
      return createMethod(data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['methods']);
    },
  });
};

export const useInvite = () => {
  return useMutation({
    mutationFn: (data: {email: string; firstName: string; lastName: string; role: string}) => {
      return invite(data);
    },
  });
};

export const useDeleteMethod = (id: number) => {
  return useMutation({
    mutationFn: () => {
      return deleteMethod(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['methods']);
    },
  });
};

export const useUpdateMethod = (id: number) => {
  return useMutation({
    mutationFn: (data: FormData) => {
      return updateMethod(id, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['methods']);
    },
  });
};

export const useMethodSubcategories = () => {
  return useQuery<ToolboxObject.Subcategory[]>(
    ['subcategories'],
    () => {
      return getMethodSubcategories();
    },
    {staleTime: 3600000, cacheTime: 3600000}
  );
};

export const useRecipeCategories = () => {
  return useQuery<ToolboxObject.WorkshopCategory[]>(
    ['recipeCategories'],
    () => {
      return getRecipeCategories();
    },
    {staleTime: 3600000, cacheTime: 3600000}
  );
};

export const useRecipes = (params?: ToolboxObject.FindWorkshopParams) => {
  return useQuery(
    ['recipes', params],
    () => {
      return getRecipes(params);
    },
    {staleTime: 3600000, cacheTime: 3600000}
  );
};

export const useWorkshops = () => {
  return useQuery(
    ['workshops'],
    () => {
      return getWorkshops();
    },
    {staleTime: 3600000, cacheTime: 3600000}
  );
};

export const useWorkshop = (id: number, recipe?: boolean) => {
  return useQuery(
    [recipe ? 'recipes' : 'workshops', id],
    () => {
      return recipe ? getRecipe(id) : getWorkshop(id);
    },
    {cacheTime: 0}
  );
};

export const useLikeMethod = (methodId: number, isLiked: boolean) => {
  return useMutation({
    mutationFn: () => {
      return toggleLikeMethod(methodId, isLiked);
    },
    onSuccess: (data) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const {is_favorite, last_viewed} = data;
      return queryClient.setQueryData(['methods', {}], (oldMethods: ToolboxObject.Method[] | undefined) => {
        return produce(oldMethods, (draft: ToolboxObject.Method[]) => {
          const index = draft?.findIndex((method) => {
            return method.id === methodId;
          });
          // eslint-disable-next-line no-param-reassign
          if (index !== undefined && index !== -1 && draft) draft[index].user_data = {is_favorite, last_viewed};
        });
      });
    },
  });
};

export const useViewMethod = (methodId: number) => {
  return useMutation({
    mutationFn: () => {
      return viewMethod(methodId);
    },
    onSuccess: (data) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const {is_favorite, last_viewed} = data;
      return queryClient.setQueryData(['methods', {}], (oldMethods: ToolboxObject.Method[] | undefined) => {
        return produce(oldMethods, (draft: ToolboxObject.Method[]) => {
          const index = draft?.findIndex((method) => {
            return method.id === methodId;
          });
          // eslint-disable-next-line no-param-reassign
          if (index !== undefined && index !== -1 && draft) draft[index].user_data = {is_favorite, last_viewed};
        });
      });
    },
  });
};

export const useViewWorkshop = (workshopId: number) => {
  return useMutation({
    mutationFn: () => {
      return viewWorkshop(workshopId);
    },
    onSuccess: (data) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const {last_viewed} = data;
      return queryClient.setQueryData(['workshops'], (oldWorkshops: ToolboxObject.Workshop[] | undefined) => {
        return produce(oldWorkshops, (draft: ToolboxObject.Workshop[]) => {
          const index = draft?.findIndex((workshop) => {
            return workshop.id === workshopId;
          });
          // eslint-disable-next-line no-param-reassign
          if (index !== undefined && index !== -1 && draft) draft[index].user_data = {is_favorite: false, last_viewed};
        });
      });
    },
  });
};

export const useLikeRecipe = (recipeId: number, isLiked: boolean) => {
  return useMutation({
    mutationFn: () => {
      return toggleLikeRecipe(recipeId, isLiked);
    },
    onSuccess: (data) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const {is_favorite, last_viewed} = data;
      return queryClient.setQueryData(['recipes'], (oldRecipes: ToolboxObject.Workshop[] | undefined) => {
        return produce(oldRecipes, (draft: ToolboxObject.Workshop[]) => {
          const index = draft?.findIndex((recipe) => {
            return recipe.id === recipeId;
          });
          // eslint-disable-next-line no-param-reassign
          if (index !== undefined && index !== -1 && draft) draft[index].user_data = {is_favorite, last_viewed};
        });
      });
    },
  });
};

export const useViewRecipe = (recipeId: number) => {
  return useMutation({
    mutationFn: () => {
      return viewRecipe(recipeId);
    },
    onSuccess: (data) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const {is_favorite, last_viewed} = data;
      return queryClient.setQueryData(['recipes'], (oldRecipes: ToolboxObject.Workshop[] | undefined) => {
        return produce(oldRecipes, (draft: ToolboxObject.Workshop[]) => {
          const index = draft?.findIndex((recipe) => {
            return recipe.id === recipeId;
          });
          // eslint-disable-next-line no-param-reassign
          if (index !== undefined && index !== -1 && draft) draft[index].user_data = {is_favorite, last_viewed};
        });
      });
    },
  });
};

export const useSaveWorkshop = (isRecipe?: boolean) => {
  return useMutation({
    mutationFn: (data: {
      id: number;
      sessions: ToolboxObject.WorkshopSession[];
      title: string;
      description: string;
      requirements: string[];
      benefits: string[];
      category: ToolboxObject.WorkshopCategory | null;
      teaser: string | null;
      checklist: ToolboxObject.ChecklistTask[];
    }) => {
      const saveFunction = isRecipe ? saveRecipe : saveWorkshop;
      return saveFunction(data.title, data.sessions, data.id, data.description, data.requirements, data.benefits, data.category, data.teaser, data.checklist);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([isRecipe ? 'recipes' : 'workshops']);
    },
  });
};

export const useUploadMethodAttachments = () => {
  return useMutation({
    mutationFn: uploadMethodAttachments,
  });
};

export const useDeleteMethodAttachments = () => {
  return useMutation({
    mutationFn: deleteMethodAttachments,
  });
};

export const useMiroBoardFromWorkshop = (workshopId: number) => {
  return useMutation({
    mutationFn: () => {
      return miroBoardFromWorkshop(workshopId);
    },
    onSuccess: async (data) => {
      await queryClient.invalidateQueries(['workshop', workshopId]);
      await queryClient.invalidateQueries(['workshops']);
      window.open(data, '_blank', 'noreferrer');
    },
  });
};

export const useLinkMiroBoardToWorkshop = (workshopId: number) => {
  return useMutation({
    mutationFn: (href: string | null) => {
      return linkMiroBoardToWorkshop(workshopId, href);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['workshop', workshopId]);
      await queryClient.invalidateQueries(['workshops']);
    },
  });
};

export const useCreateWorkshop = (isRecipe?: boolean) => {
  return useMutation({
    mutationFn: (data: {
      sessions: ToolboxObject.WorkshopSession[];
      title: string;
      description: string;
      requirements: string[];
      benefits: string[];
      category?: ToolboxObject.WorkshopCategory | null;
      teaser?: string | null;
      checklist: ToolboxObject.ChecklistTask[];
    }) => {
      const createFunction = isRecipe ? createRecipe : createWorkshop;
      return createFunction(
        data.title,
        data.sessions,
        data.description,
        data.requirements,
        data.benefits,
        isRecipe ? data.category ?? null : null,
        isRecipe ? data.teaser ?? null : null,
        data.checklist
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([isRecipe ? 'recipes' : 'workshops']);
    },
  });
};

export const useCreateRecipe = () => {
  return useCreateWorkshop(true);
};

export const useDeleteWorkshop = (isRecipe?: boolean) => {
  return useMutation({
    mutationFn: (data: {id: number}) => {
      const deleteFunction = isRecipe ? deleteRecipe : deleteWorkshop;
      return deleteFunction(data.id);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([isRecipe ? 'recipes' : 'workshops']);
    },
  });
};

export const useSelf = () => {
  return useQuery(['me'], () => {
    return getSelf();
  });
};

export const useDisconnectMiro = () => {
  return useMutation({
    mutationFn: () => {
      return disconnectMiro();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['me']);
    },
  });
};

export const useTranlsations = () => {
  return useQuery(['translations'], () => {
    return getTranslations();
  });
};

export const useUpdateTranlsation = () => {
  return useMutation({
    mutationFn: (data: ToolboxObject.Translation) => {
      const {id, ...rest} = data;
      return updateTranslation(id, rest);
    },
    onSuccess: async (updatedTranslation: ToolboxObject.Translation) => {
      queryClient.setQueryData(['translations'], (translations: ToolboxObject.Translation[] = []) => {
        return translations.map((t) => (t.id === updatedTranslation.id ? updatedTranslation : t));
      });
    },
  });
};

export const useCreateTranlsation = () => {
  return useMutation({
    mutationFn: (data: Omit<ToolboxObject.Translation, 'id'>) => {
      return createTranslation(data);
    },
    onSuccess: async (createdTranslation: ToolboxObject.Translation) => {
      queryClient.setQueryData(['translations'], (translations: ToolboxObject.Translation[] = []) => {
        return [createdTranslation, ...translations];
      });
    },
  });
};

export const useDeleteTranlsation = () => {
  return useMutation({
    mutationFn: (id: number) => {
      return deleteTranslation(id);
    },
    onSuccess: async (deletedId: number) => {
      queryClient.setQueryData(['translations'], (translations: ToolboxObject.Translation[] = []) => {
        return translations.filter((t) => t.id !== deletedId);
      });
    },
  });
};

export * from '../../components/LoadingComponent/LoadingComponent';
