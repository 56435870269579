import React from 'react';

export const ThreeDots = ({...props}) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.25 10C5.25 10.6904 4.69036 11.25 4 11.25C3.30964 11.25 2.75 10.6904 2.75 10C2.75 9.30964 3.30964 8.75 4 8.75C4.69036 8.75 5.25 9.30964 5.25 10ZM11.25 10C11.25 10.6904 10.6904 11.25 10 11.25C9.30964 11.25 8.75 10.6904 8.75 10C8.75 9.30964 9.30964 8.75 10 8.75C10.6904 8.75 11.25 9.30964 11.25 10ZM17.25 10C17.25 10.6904 16.6904 11.25 16 11.25C15.3096 11.25 14.75 10.6904 14.75 10C14.75 9.30964 15.3096 8.75 16 8.75C16.6904 8.75 17.25 9.30964 17.25 10Z"
        fill="black"
        stroke="#1F2933"
        strokeWidth="1.5"
      />
    </svg>
  );
};
