import React from 'react';
import {useLocale} from 'hooks';
import {NavLinkProps, NavLink} from 'react-router-dom';

export const LocalizedNavLink = (props: NavLinkProps) => {
  const locale = useLocale();
  const to = props.to.toString()[0] === '/' ? props.to.toString().slice(1) : props.to;
  const link = `/${locale}/${to}`;
  return <NavLink {...props} to={link} />;
};
