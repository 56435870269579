import React, {useState} from 'react';
import produce from 'immer';
import classNames from 'classnames';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Event from '@ckeditor/ckeditor5-utils/src/eventinfo';
import {TextEditor} from 'components/TextEditor';
import {useOutsideEditorClick} from 'hooks';
import {RichTypography} from 'components/RichTypography';

interface EditableMethodDescriptionProps {
  method: ToolboxObject.EditableMethod;
  description: string | null;
  update: any;
}

export const EditableMethodDescription: React.FC<EditableMethodDescriptionProps> = ({method, description, update}) => {
  const [editingValue, setEditingValue] = useState(description || '');
  const [isEditing, setIsEditing] = useState(false);

  const onChange = (_: Event, editor: ClassicEditor) => {
    return setEditingValue(editor.getData());
  };

  const onBlur = () => {
    update(
      produce(method, (draft) => {
        draft.teaser = editingValue;
      })
    );
    setIsEditing(false);
  };
  const showDescription = editingValue && editingValue.trim() !== '';
  const divRef = useOutsideEditorClick(onBlur);
  return (
    <div className="w-full h-full group-hover/step:bg-white rounded-md border group-hover/step:border-gray-100 border-white" ref={divRef}>
      {isEditing ? (
        <TextEditor data={editingValue} onChange={onChange} />
      ) : (
        <RichTypography
          key={description}
          onClick={(event) => {
            event.stopPropagation();
            setIsEditing(true);
          }}
          className={classNames(
            'px-3 py-1 text-xs sm:text-sm leading-5 font-medium text-gray-500 cursor-pointer rounded-md border hover:border-gray-100 border-white hover:text-black',
            showDescription ? '' : 'italic'
          )}
          text={showDescription ? editingValue : '+ Add Description'}
        />
      )}
    </div>
  );
};
